import React, { useMemo } from "react";

import * as api from "../../api";
import TableComponent from "../tables/TableComponent";
import { DeleteCell, TextCell, NumberCell } from "../tables/EditableCells";
import MobileTableComponent from "../tables/MobileTableComponent";
import { calcCapacity } from "../../utils";

const OverheadsTable = ({ dispatch, data, mobile }) => {
  const bookedHoursColumn = "Booked Hours/Week";
  const availableHoursColumn = "Available Hours/Week";
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: (props) => TextCell({ props, api: api.patchStaff, mobile }),
      },
      {
        Header: availableHoursColumn,
        accessor: "availableHours",
        Cell: (props) =>
          NumberCell({
            props,
            api: api.patchStaff,
            mobile,
            min: props.row.original.hours,
            minFeedback: bookedHoursColumn,
            fixed: 1,
          }),
      },
      {
        Header: bookedHoursColumn,
        accessor: "hours",
        Cell: (props) =>
          NumberCell({
            props,
            api: api.patchStaff,
            mobile,
            max: props.row.original.availableHours,
            maxFeedback: availableHoursColumn,
            fixed: 1,
          }),
      },
      {
        Header: "Capacity %",
        accessor: (row) => calcCapacity(row).toFixed(1),
      },
      {
        Header: "Delete",
        accessor: "delete",
        Cell: (props) => DeleteCell({ props, api: api.deleteStaff, mobile }),
      },
    ],
    [mobile, availableHoursColumn, bookedHoursColumn]
  );

  return mobile ? (
    <MobileTableComponent
      data={data}
      columns={columns}
      dispatch={dispatch}
      buttonTextFormatter={(staff) =>
        `${staff.name}, ${(+staff.hours).toFixed(
          1
        )} booked hours/week. At ${calcCapacity(staff).toFixed(1)}% capacity.`
      }
    />
  ) : (
    <TableComponent columns={columns} data={data} updateMyData={dispatch} />
  );
};

export default OverheadsTable;
