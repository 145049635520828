import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import { mainTheme } from "./styles/themes.styles";
import GlobalStyles from "./styles/global.styles";

import App from "./App";

import "mobx-react-lite/batchingForReactDom";
import { ThemeProvider } from "styled-components";
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
      <GlobalStyles />
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
