import React from "react";

import { Card } from "react-bootstrap";

const MobileTableRow = ({ dispatch, index, row, columns }) => {
  return (
    <Card.Body className="mobile-table-row">
      {columns.map((column) => {
        /* If our accessor is a function, we render it's information in accordion header, not here */
        return (
          (typeof column.accessor === "string" ||
            column.accessor instanceof String) && (
            <React.Fragment
              key={`mobile-table-row-column-${row._id}-${column.accessor}`}
            >
              {column.Cell({
                value: row[column.accessor],
                row: { index, original: row },
                column: { id: column.accessor, Header: column.Header },
                updateMyData: dispatch,
              })}
            </React.Fragment>
          )
        );
      })}
    </Card.Body>
  );
};

// use React.memo to only rerender the row when its data has changed
// https://reactjs.org/docs/react-api.html#reactmemo
const areEqual = (prevProps, nextProps) => {
  return prevProps.row === nextProps.row;
};

export default React.memo(MobileTableRow, areEqual);
