import React from "react";

import { gs } from "../../stores";
import { useInit } from "../../hooks";
import * as api from "../../api";

import { GoBack } from "../buttons";

import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const Overview = () => {
  const [state] = useInit(reducer, initialState, {
    api: api.getStaff,
    type: "set-data",
  });

  if (!gs.isAuthenticated()) return gs.redirect();

  return (
    <Styles>
      <h2>Overview/Troubleshooting</h2>
      {state.isLoading ? (
        <Spinner animation="border" className="spinner" />
      ) : (
        <>
          <p className="margin-top">Okay Boss, here is what you’ve told me…</p>
          <ul>
            <li>
              You want your gross monthly business profits to be:{" "}
              <span className="bold">
                {(+state.user.income / 12).toFixed(2)}
              </span>
              , and your gross annual business profits to be{" "}
              <span className="bold">{(+state.user.income).toFixed(2)}</span>{" "}
              (Remember that you’ll need to account for taxes etc from this!)
            </li>
            <li>
              Your total annual business outgoings are:{" "}
              <span className="bold">
                {(+state.user.totalAnnualOutgoings).toFixed(2)}
              </span>
            </li>
            <li>
              Your business will be open on average for{" "}
              <span className="bold">
                {(+state.user.weeksWorked).toFixed(1)}
              </span>{" "}
              number of weeks per year
            </li>
            <li>
              Your team is made up of:{" "}
              <ul>
                {state.staff.map((staff, i) => {
                  return (
                    <li className="bold" key={`${staff.name}-${i}`}>
                      {staff.name}
                    </li>
                  );
                })}
              </ul>
            </li>
            <li>
              Your total booked hours are:{" "}
              <span className="bold">
                {(+state.user.hoursPerWeek).toFixed(1)}
              </span>{" "}
            </li>
            <li>
              Your total average capacity is:{" "}
              <span className="bold">
                {(+state.user.avgUtilisation).toFixed(1)}
              </span>{" "}
            </li>
            <li>
              Each person needs to bring in:{" "}
              <span className="bold">
                {(+state.user.hourlyTotal).toFixed(2)}
              </span>{" "}
              per hour (plus stock), which works out as{" "}
              <span className="bold">
                {(+state.user.minuteTotal).toFixed(2)}
              </span>{" "}
              per minute (plus stock)
            </li>
          </ul>
          <p>
            If anything doesn’t look right, just pop back to the relevant page,
            and any changes you make will automatically update this overview
            page.
          </p>
          <p>
            Your services page is now your go-to resource when building your
            price list with profit in mind!
          </p>
          <p>
            Stick with these and charge AT LEAST the RRP figures, and you’ll be
            on your way to reach your goals.
          </p>
          <p>
            If you’re unsure of any figures, or they vary slightly, always go
            ‘worst case scenario’ and over-estimate.
          </p>
          <p>
            E.g. If you’re usually booked between 30-35 hours per week out of
            40, input 30 as your booked hours, just to be safe.
          </p>
          <p>
            If you need any support or have any questions, pop them into our
            community support group, over on{" "}
            <a href="https://www.facebook.com/groups/bossyourprofits">
              Facebook
            </a>
            .
          </p>
          <p>Enjoy every penny, Boss.</p>
          <p>Maddi x</p>
        </>
      )}
      <div className="footer">
        <GoBack route="/services" />
      </div>
    </Styles>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set-data":
      state.staff = action.data.staff;
      state.user = action.data.user;
      state.isLoading = false;

      return;
    case "error":
      gs.err = {
        response: action.response,
        route: "/overview",
      };
      return;
    default:
      return state;
  }
};

const initialState = {
  staff: [],
  isLoading: true,
  user: {
    weeksWorked: null,
    totalAnnualOutgoings: null,
    income: null,
    weeklyTotal: null,
    HourlyTotal: null,
    minuteTotal: null,
    avgUtilisation: null,
    hoursPerWeek: null,
  },
};

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .margin-top {
    margin-top: 2rem;
  }

  .footer {
    margin-top: 1rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;

export default Overview;
