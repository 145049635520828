import React from "react";

import { gs } from "../../stores";

import { Next } from "../buttons";

import styled from "styled-components";

const Home = () => {
  if (!gs.isAuthenticated()) return gs.redirect();

  return (
    <Styles>
      <h2>Tutorial</h2>
      <p>
        Hey there Boss, I can’t wait to help you work out your correct pricing!
      </p>
      <p>
        Watch the video below where I’ll walk you through each stage of Boss
        Your Profits.
      </p>
      <p>
        And don’t forget to check out your online course portal for handy
        modules that go into detail on how to complete each stage. You can find
        your class portal{" "}
        <a href="https://boss-your-profits-pricing-app.simplerosites.com/courses/26975-boss-your-profits-pricing-app-pricing">
          HERE
        </a>
        .
      </p>
      <iframe
        src="https://www.youtube.com/embed/wQq01p_DzKI?si=77mQv5nglFxIkTDq"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen={true}
        title="Tutorial video"
      />
      <Next route="/income" />
    </Styles>
  );
};

export default Home;

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;

  iframe {
    align-self: center;
    width: 50vw;
    height: 40vw;
    margin: 8px 0 16px 0;
  }

  .btn-primary {
    align-self: flex-end;
  }
`;
