import React from "react";

const PrivacyPolicy = () => {
  return <div>  
    <p>This privacy notice explains how Boss Your Salon Ltd looks after personal information you give us or that we learn by having you as a customer and the choices you make about marketing communications you agree we may send you. This notice explains how we do this and tells you about your privacy rights and how the law protects you.</p>
    <p>TOPICS:</p>
    <ul>
      <li>What information we collect about you</li>      
      <li>How information about you will be used</li>
      <li>Marketing Employment</li>
      <li>How long your information will be kept for</li>
      <li>Where your information is kept</li>
      <li>Access to your information and correction</li>
      <li>Cookies</li>
      <li>Other websites</li>
      <li>Changes to our privacy notice</li>
      <li>How to contact us</li>
    </ul> 
    <p>WHAT INFORMATION WE COLLECT ABOUT YOU</p>
    <p>In law, we are allowed to use personal information, including sharing it outside the company, only if we have a proper reason to do so, for example:</p>
    <ul>
      <li>When it is in our legitimate interest i.e. there is a business or commercial reason to do so, unless this is outweighed by your rights or interests</li>
    </ul>
    <p>We may therefore hold your information on:</p>
    <ul>
      <li> Providers of our course software system (Simplero) </li>
      <li> Mailing houses (MailChimp)  </li>
      <li> Suppliers of our website (123-Reg and GoDaddy.com) </li>
      <li>Payment processors (PayPal, Stripe, GoCardless)  </li>
    </ul>
    <p>We have rigorous data protection and security policies in place with all our suppliers. <br/>We will not share your information with any other third party without your consent except to help prevent fraud, or if required to do so by law.</p>
    <p>MARKETING</p>
    <p>We would like to send you information about products and services which may be of interest to you. We will ask for your consent to receive marketing information. If you have consented to receiving marketing, you may opt out at a later date. <br/>You have the right at any time to stop us from contacting you for marketing purposes or giving your information to third party suppliers of products or services. If you no longer wish to be contacted for marketing purposes, please contact us by email via our ‘Contact Us’ page.</p>
    <p>HOW LONG YOUR INFORMATION WILL BE KEPT FOR</p>
    <p>Unless you request otherwise, we will keep your information to contact you for a maximum of 3 years from your last purchase, or when you joined our mailing list, whichever is latest. <br/>After this period we will delete all your personal information, except for your name, financial transactions (which we are obliged to keep for 6 years). See our data retention policy for further information, including employee data.</p>
    <p>WHERE YOUR INFORMATION IS KEPT</p>
    <p>This information is encrypted and held in the cloud with our online class system provider (Simplero). Your information is stored within the European Economic Area on secure servers provided by Simplero. Any payment transactions are encrypted. Sending information via the internet is not completely secure, although we will do our best to protect your information and prevent unauthorised access.</p>
    <p>ACCESS TO YOUR INFORMATION AND CORRECTION</p>
    <p>You have the right to request a copy of the personal information that we hold about you. This will normally be free, unless we consider the request to be unfounded or excessive, in which case we may charge a fee to cover our administration costs. <br/>If you would like a copy of some or all of your personal information, please contact us via email (maddi@bossyoursalon.com). <br/>We want to make sure that your personal information is accurate and up-to-date. You may ask us to correct or remove information you think is inaccurate. <br/>You have the right to ask us to object to our use of your personal information, or to ask us to delete, remove or stop using your personal information if there is no need for us to keep it.</p>
    <p>E-NEWSLETTERS</p>
    <p>We email e-newsletters to inform you about products and services provided by our business, as well as regular updates via our newsletter. You have the opportunity to unsubscribe from e-newsletters at any time. <br/>E-newsletters may contain subscriber tracking facilities within the actual email, for example, whether emails were opened or forwarded, which links were clicked on within the email content, the times, dates and frequency of activity. We use this information to refine future email campaigns and provide you with more relevant content based around your activity.</p>
    <p>COOKIES</p>
    <p>Cookies are text files placed on your computer to collect standard internet log information and visitor behaviour information. This is used to track visitor use of the website and to compile statistical reports on website activity. For further information visit www.aboutcookies.org or www.allaboutcookies.org <br/>You can set your browser not to accept cookies and the above websites tell you how to remove cookies from your browser. However, in a few cases some of our website features may not function as a result.</p>
    <p>OUR COOKIE POLICY</p>
    <p>We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our website with our social media accounts, advertising and analytics partners, who may combine it with other information that you have provided to them, or that they’ve collected from your use of their services. You consent to our cookies if you continue using this website.</p>
    <p>OTHER WEBSITES</p>
    <p>Our website includes links to other websites. This privacy notice only applies to this website so when you link to other websites you should read their own privacy notices.</p>
    <p>CHANGES TO OUR PRIVACY NOTICE</p>
    <p>We keep our privacy notice under regular review and we will place any updates on this webpage. This privacy notice was last updated on 4th October 2020.</p>
    <p>HOW TO CONTACT US</p>
    <p>Please contact us if you have any questions about our privacy notice or information we hold about you: <br/>By email: maddi@bossyoursalon.com <br/>Or write to us at: Boss Your Salon Ltd, Urban Creative, 12C Enterprise Court, Seaham Grange Industrial Estate, Seaham SR7 0PS</p>
    <p>You also have the right to complain to the Information Commissioner’s Office. Find out on their website how to report a concern: <a href="https://ico.org.uk/make-a-complaint/">www.ico.org.uk/concerns/handling</a></p>
    </div>;
};

export default PrivacyPolicy;