import React from "react";

import { useInit } from "../../hooks";
import * as api from "../../api";
import { gs } from "../../stores";

import { Next, GoBack, Help } from "../buttons";
import OverheadsTable from "./OverheadsTable";
import PostStaff from "./PostStaff";

import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import { observer } from "mobx-react";

const Overheads = observer(() => {
  const [state, dispatch] = useInit(reducer, initialState, {
    api: api.getStaff,
    type: "set-data",
  });

  if (!gs.isAuthenticated()) return gs.redirect();

  return (
    <Styles>
      <div className="header-help">
        <h2>People & Hours</h2>
        <Help bodyKey={"staff"} />
      </div>
      <PostStaff dispatch={dispatch} />
      <p className="number-of-staff">
        Number of income generating staff: {state.staff.length}
      </p>
      {state.isLoading ? (
        <Spinner animation="border" className="spinner" />
      ) : (
        <OverheadsTable
          data={state.staff}
          dispatch={dispatch}
          mobile={gs.isMobile}
        />
      )}
      <p className="hours-per-week">
        Your teams total number of bookable hours per week:{" "}
        {(+state.user.availableHoursPerWeek).toFixed(1)}
      </p>
      <p className="average-capacity">
        Your teams average capacity: {(+state.user.avgUtilisation).toFixed(1)}%
      </p>
      <p className="hourly-total">
        Hourly total per person: {state.user.hourlyTotal}
      </p>
      <p className="minute-total">
        Minute total per person: {state.user.minuteTotal}
      </p>
      <div className="footer">
        <GoBack route="/weeks-worked" />
        <Next route="/products" />
      </div>
    </Styles>
  );
});

export default Overheads;

const reducer = (state, action) => {
  switch (action.type) {
    case "set-data":
      state.staff = action.data.staff;
      state.user = action.data.user;
      state.isLoading = false;

      return;
    case "delete-data":
      state.staff = state.staff.filter((_, index) => index !== action.index);
      state.user = action.data.user;

      return;
    case "add-data":
      state.staff = [...state.staff, { ...action.data.staff }];
      state.user = action.data.user;

      return;
    case "update-data":
      state.staff[action.index] = action.data.staff;
      state.user = action.data.user;

      return;
    case "error":
      gs.err = {
        response: action.response,
        route: "/business-breakdown",
      };
      return;
    default:
      return state;
  }
};

const initialState = {
  staff: [],
  isLoading: true,
  // user fields in use on this page
  user: {
    weeksWorked: null,
    avgUtilisation: null,
    hoursPerWeek: null,
    availableHoursPerWeek: null,
    hourlyTotal: null,
    minuteTotal: null,
  },
};

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .header-help {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .post-staff-form {
    margin: 2rem 0rem 1rem 0rem;

    .form-control {
      max-width: 300px;
    }
  }

  .number-of-staff {
    margin-top: 1rem;
    font-weight: bold;
  }

  .hours-per-week {
    margin: 0rem;
    font-weight: bold;
  }

  .hourly-total {
    margin-bottom: 0rem;
    font-weight: bold;
  }

  .minute-total {
    margin: 2rem 0rem;
    font-weight: bold;
  }

  .average-capacity {
    margin: 2rem 0rem;
    font-weight: bold;
  }

  .footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;
