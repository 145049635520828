import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { useForm, useToggle } from "../../hooks";
import Form from "react-bootstrap/Form";
import SelectOptions from "../tables/SelectOptions";
import bysLogo from "../../logos/bys-logo.png";

const imageUrl = process.env.REACT_APP_IMAGES_HOST;

const initialForm = {
  serviceName: "",
  serviceCost: 0.0,
  duration: 5,
  products: [],
};

const formatProductOptions = (products, selected) => {
  const productOptions = products.map((product, i) => {
    const match = selected.filter(
      (selectedProduct) => selectedProduct.product._id === product._id
    );

    if (match.length > 0) return <></>;

    return (
      <option key={`${product._id}`} value={i}>{`${product.name}`}</option>
    );
  });

  return [
    <option key="" value={undefined}>
      Please select a product...
    </option>,
    ...productOptions,
  ];
};

const ProductRow = ({
  index,
  product: { product, amount },
  invalid,
  setForm,
}) => {
  const isInvalid = invalid[index];

  const handleChange = (e) => {
    e.preventDefault();

    const value = e.target.value;
    return setForm((c) => {
      c.products[index].amount = value;
    });
  };

  return (
    <StyledProductRow letters={product.unit.length}>
      <div>
        <div className="post-patch-service-product-name">
          <img
            className="product-img"
            src={`${imageUrl}/${product.userId}/products/${product._id}`}
            alt={product.name}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = bysLogo; // default image stored locally
            }}
          />
          {product.name}:
        </div>
        <Form.Group controlId="post-patch-service-product-amount">
          <Form.Label srOnly>Amount</Form.Label>
          <Form.Control
            as="input"
            type="text"
            value={amount}
            onChange={handleChange}
            required
            placeholder={`Amount in ${product.unit}`}
            isInvalid={isInvalid}
          />
          <div>{product.unit}</div>
          {isInvalid && (
            <Form.Control.Feedback type="invalid">
              Must be a positive, no currency symbols or commas.
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </div>
      <Button
        variant="danger"
        size="sm"
        onClick={() =>
          setForm((c) => {
            c.products.splice(index, 1);
          })
        }
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </StyledProductRow>
  );
};

const StyledProductRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  .post-patch-service-product-name {
    min-width: 12rem;
    max-width: 12rem;
  }

  div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-right: 0.5rem;

    img {
      align-self: flex-start;
      margin-right: 0.5rem;
      max-width: 3rem;
      max-height: 3rem;
    }
  }

  .form-group {
    margin: 0;
  }

  .form-control {
    margin-right: 0.5rem;
    max-width: 7rem;
    min-width: 7rem;
  }

  .btn-primary {
    align-self: flex-end;
  }

  @media (max-width: 575px) {
    .form-control {
      min-width: 0rem;
    }

    .post-patch-service-product-name {
      min-width: 0rem;
    }
  }
`;

const PostService = ({
  dispatch,
  api,
  tableId,
  tableIndex,
  index,
  mobile = false,
  initialData,
  products = [],
}) => {
  const {
    form,
    handleChange,
    setForm,
    isMounted,
    makingRequest,
    setMakingRequest,
  } = useForm(initialData ? { ...initialData } : { ...initialForm });
  const [editing, handleEditing] = useToggle(false);
  const [value, setValue] = useState();
  const [invalidCost, setInvalidCost] = useState(false);
  const [invalidCharge, setInvalidCharge] = useState(false);
  const [invalidProduct, setInvalidProduct] = useState({});

  useEffect(() => {
    if (value === undefined) return;

    setForm((c) => {
      if (!Array.isArray(c.products)) {
        c.products = [{ product: products[value], amount: 0 }];
        return;
      }
      c.products.push({ product: products[value], amount: 0 });
    });

    setValue(undefined);
  }, [value, products, setForm]);

  const onClick = (e) => {
    e.preventDefault();
    setInvalidCharge(false);
    setInvalidCost(false);
    setInvalidProduct({});

    const cost = form.serviceCost;
    if (+cost < 0 || isNaN(+cost)) {
      return setInvalidCost(true);
    }

    const charge = form.serviceCharge;
    if (+charge < 0 || isNaN(+charge)) {
      return setInvalidCharge(true);
    }

    // Validate whilst building correct format for API.
    let isInvalid = false;
    let invalidProductMap = {};
    const formattedProducts = form.products.map((product, i) => {
      const amount = product.amount;
      if (amount === "" || +amount < 0 || isNaN(+amount)) {
        isInvalid = true;
        invalidProductMap[i] = true;
      }

      return { amount, product: product.product._id };
    });

    if (isInvalid) {
      return setInvalidProduct(invalidProductMap);
    }

    setMakingRequest(true);
    api({ ...form, products: formattedProducts }, tableId, index)
      .then(({ data }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          handleEditing();
          return dispatch({
            type: initialData ? "update-data" : "add-data",
            index,
            tableIndex,
            data,
          });
        }
      })
      .catch(({ response }) => {
        return dispatch({
          type: "error",
          response,
        });
      });
    return;
  };

  const titleText = initialData ? "Edit service" : "Add service";
  const buttonText = initialData ? "Edit" : "Add";

  return (
    <Form onSubmit={onClick} className="post-patch-service-form">
      <Modal
        show={editing}
        onHide={handleEditing}
        centered
        aria-labelledby="contained-modal-title-center"
      >
        <Modal.Header closeButton>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="post-patch-service-name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={form.serviceName}
              onChange={(e) => handleChange(e, "serviceName")}
              required
              placeholder="Service name. E.g. Cut & Blow Dry Short."
            />
          </Form.Group>
          <Form.Group controlId="post-patch-service-cost">
            <Form.Label>Extra cost</Form.Label>
            <Form.Control
              as="input"
              type="text"
              value={form.serviceCost}
              onChange={(e) => handleChange(e, "serviceCost")}
              required
              placeholder="E.g 10"
              isInvalid={invalidCost}
            />
            {invalidCost && (
              <Form.Control.Feedback type="invalid">
                Must be a positive, no currency symbols or commas.
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="post-patch-service-duration">
            <Form.Label>Length</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => handleChange(e, "duration")}
              value={form.duration}
              disabled={makingRequest}
            >
              <SelectOptions id={"duration"} />
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="post-patch-service-charge">
            <Form.Label>Current price</Form.Label>
            <Form.Control
              as="input"
              type="text"
              value={form.serviceCharge}
              onChange={(e) => handleChange(e, "serviceCharge")}
              required
              placeholder="E.g 10"
              isInvalid={invalidCharge}
            />
            {invalidCharge && (
              <Form.Control.Feedback type="invalid">
                Must be a positive, no currency symbols or commas.
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="post-patch-service-products">
            <Form.Label>Choose a product</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setValue(e.target.value)}
              placeholder="Select a product..."
              value={value}
              disabled={makingRequest}
            >
              {formatProductOptions(products, form.products)}
            </Form.Control>
          </Form.Group>
          {form.products &&
            form.products.map((product, i) => {
              return (
                <ProductRow
                  key={product.product._id}
                  index={i}
                  product={product}
                  setForm={setForm}
                  invalid={invalidProduct}
                />
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            size="sm"
            onClick={onClick}
            disabled={makingRequest}
          >
            Save
          </Button>
          <Button variant="secondary" onClick={handleEditing} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {!editing && (
        <Button
          variant="success"
          size="sm"
          onClick={handleEditing}
          disabled={makingRequest}
        >
          {mobile ? (
            buttonText
          ) : (
            <FontAwesomeIcon icon={initialData ? faEdit : faPlus} />
          )}
        </Button>
      )}
    </Form>
  );
};

export default PostService;
