import React, { useMemo } from "react";

import { calcRRP, calcGrossProfit, calcDifference } from "../../utils";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import MobileServiceForm from "./MobileServiceForm";

const MobileServiceTableComponent = ({
  service,
  dispatch,
  index,
  tableIndex,
  tableId,
  user,
  products,
}) => {
  return useMemo(() => {
    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={`${index}`}
          className="mobile-table-column"
        >
          <button className="accordion-button">
            {service.serviceName} - RRP:{" "}
            {calcRRP(service.duration, service.totalCost, user).toFixed(2)},
            Gross profit: {calcGrossProfit(service.duration, user).toFixed(2)},{" "}
            Difference:{" "}
            {calcDifference(
              service.duration,
              service.totalCost,
              service.serviceCharge,
              user
            )}
          </button>
          <FontAwesomeIcon icon={faAngleDown} className="arrowIcon" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={`${index}`}>
          <Card.Body className="mobile-table-row">
            <MobileServiceForm
              service={service}
              index={index}
              dispatch={dispatch}
              tableIndex={tableIndex}
              tableId={tableId}
              products={products}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }, [index, service, dispatch, user, tableIndex, tableId, products]);
};

export default MobileServiceTableComponent;
