import React from "react";
import { formatDurationText } from "../../utils";

const SelectOptions = ({ id }) => {
  return options[id].map((option) => {
    return (
      <option
        key={`${option.value}-${option.text}`}
        value={option.value}
      >{`${option.text}`}</option>
    );
  });
};

const formatDurationOptions = () => {
  const maxValueIncrements5 = 300;
  const options = [];
  let i = 0;
  for (let v = 5; v <= maxValueIncrements5; v += 5) {
    options[i] = { value: v, text: formatDurationText(v) };
    i++;
  }

  const maxValueIncrements15 = 480;

  for (let v = 315; v <= maxValueIncrements15; v += 15) {
    let hours = Math.floor(v / 60);
    let minutes = v % 60;

    options[i] = { value: v, text: `${hours} hours ${minutes} minutes` };

    i++;
  }

  return options;
};

const formatRegularityOptions = () => {
  return [
    { value: 52, text: "Weekly" },
    { value: 13, text: "4 weekly" },
    { value: 12, text: "Monthly" },
    { value: 4, text: "Quarterly" },
    { value: 1, text: "Annually" },
  ];
};

const formatUnitOptions = () => {
  return [
    { value: "uses", text: "Uses" },
    { value: "g", text: "Grams" },
    { value: "ml", text: "Millilitres" },
    { value: "oz", text: "Ounces" },
  ];
};

const options = {
  unit: formatUnitOptions(),
  duration: formatDurationOptions(),
  regularity: formatRegularityOptions(),
};

export default SelectOptions;
