import React from "react";

import { useInit } from "../../hooks";
import * as api from "../../api";
import { gs } from "../../stores";
import { Next, GoBack, Help } from "../buttons";
import PostOutgoing from "./PostOutgoing";
import OutgoingsTable from "./OutgoingsTable";

import { observer } from "mobx-react";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const Outgoings = observer(() => {
  const [state, dispatch] = useInit(reducer, initialState, {
    api: api.getOutgoings,
    type: "set-data",
  });

  if (!gs.isAuthenticated()) return gs.redirect();

  return (
    <Styles>
      <div className="header-help">
        <h2>Business outgoings</h2>
        <Help bodyKey={"outgoings"} />
      </div>
      <p className="outgoings-description">
        Now it’s time to enter your business outgoings, don’t forget those
        sneaky ones that come out quarterly or yearly!
      </p>
      <p>
        <b>Don’t</b> include your wage here, as it will be factored into the
        ‘Income’ page.
      </p>
      <p>
        <b>Don’t</b> include stock costs here, as these will be factored in at a
        later stage.
      </p>
      <p>
        Got some you need to add? Use the box at the bottom to add custom
        outgoings.
      </p>
      <p>
        Accidentally deleted a row? Check out the list of outgoings{" "}
        <a href="https://docs.google.com/document/d/1wjNT56otQQBb6USA2N-QhlnBVbFII2i59tGIjkJC3uA/edit?usp=sharing">
          HERE
        </a>
      </p>
      {state.isLoading ? (
        <Spinner animation="border" className="spinner" />
      ) : (
        <OutgoingsTable
          data={state.outgoings}
          dispatch={dispatch}
          mobile={gs.isMobile}
        />
      )}
      <PostOutgoing dispatch={dispatch} />
      <p className="total-annual">
        Total Annual Business Outgoings:{" "}
        {(+state.user.totalAnnualOutgoings).toFixed(2)}
      </p>
      <div className="footer">
        <GoBack route="/income" />
        <Next route="/weeks-worked" />
      </div>
    </Styles>
  );
});

export default Outgoings;

const reducer = (state, action) => {
  switch (action.type) {
    case "set-data":
      state.outgoings = action.data.outgoings;
      state.isLoading = false;
      state.user = action.data.user;

      return;
    case "delete-data":
      state.outgoings = state.outgoings.filter(
        (outgoing, index) => index !== action.index
      );

      state.user = action.data.user;

      return;
    case "add-data":
      state.outgoings = [...state.outgoings, { ...action.outgoing }];
      return;
    case "update-data":
      state.outgoings[action.index] = action.data.outgoing;

      state.user = action.data.user;

      return;
    case "error":
      gs.err = {
        response: action.response,
        route: "/outgoings",
      };
      return;
    default:
      return state;
  }
};

const initialState = {
  outgoings: [],
  isLoading: true,
  user: {
    totalAnnualOutgoings: null,
  },
};

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .header-help {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .outgoings-description {
    margin-top: 1rem;
  }

  .post-outgoing-form {
    .form-control {
      margin-right: 1rem;
    }
  }

  .total-annual {
    margin: 2rem 0rem;
    font-weight: bold;
  }

  .footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  @media (max-width: 575px) {
    .post-outgoing-form {
      display: flex;
      flex-flow: column nowrap;
      align-self: flex-start;

      .btn-primary {
        align-self: flex-start;
      }

      .form-control {
        margin-right: 0rem;
      }
    }
  }
`;
