import React from "react";

import { gs } from "../../stores";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { observer } from "mobx-react";
import { Link } from "@reach/router";
import styled from "styled-components";

const Navigation = observer(() => {
  return (
    <Styles>
      <Navbar expand="md" id="navbar">
        <Navbar.Brand as={Link} to="/">
          Boss Your Profits
        </Navbar.Brand>
        {gs.loggedIn && (
          <>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="justify-content-end"
            >
              <Nav>
                <Nav>
                  <Nav.Link as={Link} to="/income">
                    Income
                  </Nav.Link>
                  <Nav.Link as={Link} to="/outgoings">
                    Outgoings
                  </Nav.Link>
                  <NavDropdown
                    title="Business Breakdown"
                    id="collapsible-nav-dropdown"
                    alignRight
                  >
                    <NavDropdown.Item as={Link} to="/weeks-worked">
                      Weeks Worked
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/business-breakdown">
                      People & Hours
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={Link} to="/products">
                    Product Library
                  </Nav.Link>
                  <Nav.Link as={Link} to="/services">
                    Services
                  </Nav.Link>
                  <Nav.Link as={Link} to="/overview">
                    Overview
                  </Nav.Link>
                  <NavDropdown
                    title="Profile"
                    id="collapsible-nav-dropdown"
                    alignRight
                  >
                    <NavDropdown.Item as={Link} to="/account">
                      Account
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/privacypolicy">
                      Privacy Policy
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={gs.logout}>
                      Log out
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Navbar>
    </Styles>
  );
});

export default Navigation;

const Styles = styled.nav`
  grid-area: nav;

  #navbar {
    .navbar-brand {
      color: ${(props) => props.theme.bysTheme};
    }

    .nav-link {
      padding-left: 0.35rem;
      padding-right: 0.35rem;
    }
  }
`;
