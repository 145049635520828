import React from "react";

import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { navigate } from "@reach/router";

const NotFound = () => {
  const handleError = () => {
    navigate("/income");
  };

  return (
    <Styles>
      <h2>404</h2>
      <h3>Not Found!</h3>
      <p>Sorry but this page doesn't appear to exist or has been removed.</p>
      <Button onClick={handleError} size="sm">
        Go Back!
      </Button>
    </Styles>
  );
};

export default NotFound;

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;
