import React from "react";

import { useToggle } from "../../hooks";

import { navigate } from "@reach/router";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Modal from "react-bootstrap/Modal";

export const GoBack = ({ route }) => {
  const goBack = () => {
    navigate(`${route}`);
  };

  return (
    <Button size="sm" onClick={goBack}>
      Back
    </Button>
  );
};

export const Next = ({ route }) => {
  const next = () => {
    navigate(`${route}`);
  };
  return (
    <Button size="sm" onClick={next}>
      Next
    </Button>
  );
};

export const Help = ({ bodyKey }) => {
  const [getHelp, handleToggle] = useToggle();

  return (
    <div className="help">
      <Modal
        show={getHelp}
        onHide={handleToggle}
        aria-labelledby="modal-help"
        className="help-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Help</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body[bodyKey].map((item, index) => {
            const [header, body] = item.split(":");
            return (
              <p key={index} className="help-paragraph">
                <span className="bold">{header}:</span> {body}
              </p>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleToggle} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {!getHelp && (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip">Click for help!</Tooltip>}
        >
          <button className="helpIcon" onClick={handleToggle} type="button">
            Help <FontAwesomeIcon icon={faQuestionCircle} />
          </button>
        </OverlayTrigger>
      )}
    </div>
  );
};

const body = {
  outgoings: [
    "Outgoing: The name of the outgoing. E.g. Rent.",
    "Cost: The cost of the outgoing. E.g. 20.00.",
    "Regularity: The regularity at which each billing of an outgoing occurs. If it is billed every 3 months, pick quarterly. Billed once a year, pick annually etc.",
    "Annual Cost: The annual cost of each outgoing. This will be calculated for you.",
    "Delete: Remove the selected outgoing from your list.",
    "Total annual outgoings: This is the total annual cost of all your outgoings combined.",
    "Table: Click on a table column heading to sort by that heading, either ascending or descending.",
  ],
  staff: [
    "Number of income generating staff: This is the total number of people in your business, including yourself, who bring revenue in. If you have a team member who doesn’t generate revenue (e.g. an assistant/receptionist), don’t add them here, but do make sure their wages are included in your outgoings section.",
    "Name: The name of your team member. E.g. Maddi.",
    "Available Hours/Week: This is the number of hours, on average, they are available to be booked for client work each week.",
    "Booked Hours/Week: This is the number of hours, on average, they are typically booked with clients.",
    "Capacity: This will be calculated automatically, it shows what percentage of available hours each person is typically booked for.",
    "Delete: Use this to delete a team member.",
    "Your team’s total number of bookable hours per week: This is the total across the whole team.",
    "Your team’s average capacity: This is the average between the whole team.",
    "Hourly total per person: This is the amount each person needs to generate to cover overheads and your income goal, per hour (this doesn’t include stock, we’ll add that next).",
    "Minute total per person: This is the amount each person needs to generate to cover overheads and your income goal, per minute (this doesn’t include stock, we’ll add that next).",
  ],
  services: [
    "Service: The name of the service. E.g. Cut & Blow Dry Short.",
    "Product cost: The total cost of the products used in the service. E.g. 5.00.",
    "Time: The length of time it takes to complete the service.",
    "RRP: The amount you should charge for this service. This will be calculated for you.",
    "Delete: Remove the selected service from your list.",
    "Move: Drop and drag your services to order them.",
  ],
  products: [
    "Product: The name of the product. E.g. Tub of Loreal bleach powder.",
    "Container size: The size of the product container e.g. 1000g.",
    "Unit: The size unit of the product container. E.g. Grams. Use 'other' for things like strips etc.",
    "Cost: The cost of the product. E.g. 10.00",
    "Delete: Remove the selected product from your library.",
  ],
  weeksworked: [
    "Total annual/monthly outgoings: This is the total annual/monthly cost of all your outgoings combined with your income goal.",
    "Weekly total: The amount the business must bring in per working week to cover your business outgoing costs including your income, not including your stock.",
  ],
};
