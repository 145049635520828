import React from "react";

import CategoryHeader from "./CategoryHeader";
import PostService from "./PostService";
import DeleteCategory from "./DeleteCategory";
import MobileServiceTableComponent from "./MobileServiceTableComponent";
import * as api from "../../api";

import Accordion from "react-bootstrap/Accordion";

const MobileServiceTable = ({
  category,
  tableIndex,
  dispatch,
  user,
  products,
}) => {
  return (
    <div className="mobile-services">
      <div className="category-table-header">
        <CategoryHeader
          initialName={category.categoryName}
          dispatch={dispatch}
          tableIndex={tableIndex}
          tableId={category._id}
        />
        <div className="post-service-delete-category">
          <PostService
            dispatch={dispatch}
            tableId={category._id}
            tableIndex={tableIndex}
            api={api.postService}
            products={products}
          />
          <DeleteCategory
            name={category.categoryName}
            tableId={category._id}
            dispatch={dispatch}
            tableIndex={tableIndex}
          />
        </div>
      </div>
      <Accordion defaultActiveKey="0" className="mobile-table">
        {category.services.map((service, i) => {
          return (
            <MobileServiceTableComponent
              service={service}
              dispatch={dispatch}
              index={i}
              key={service._id}
              tableId={category._id}
              tableIndex={tableIndex}
              user={user}
              products={products}
            />
          );
        })}
      </Accordion>
    </div>
  );
};

export default MobileServiceTable;
