import React, { useMemo } from "react";

import * as api from "../../api";
import {
  calcRRP,
  calcGrossProfit,
  calcDifference,
  formatDurationText,
} from "../../utils";

import { DeleteCell } from "../tables/EditableCells";
import PostService from "./PostService.jsx";
import DnDTableComponent from "../tables/DnDTableComponent";

const ServicesTable = ({
  dispatch,
  category,
  tableIndex,
  dragTableIndex,
  dragRowIndex,
  moveRow,
  updateRowIndexes,
  products,
  user,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: category.categoryName,
        columns: [
          {
            Header: "Service",
            accessor: (row) => row.serviceName,
          },
          {
            Header: "Product/stock cost",
            accessor: (row) => (+row.totalCost).toFixed(2),
          },
          {
            Header: "Time",
            accessor: (row) => formatDurationText(+row.duration.toFixed(2)),
          },
          {
            Header: "RRP",
            accessor: (row) =>
              calcRRP(row.duration, row.totalCost, user).toFixed(2),
          },
          {
            Header: "Gross profit",
            accessor: (row) => calcGrossProfit(row.duration, user).toFixed(2),
          },
          {
            Header: "Current price",
            accessor: (row) => (+row.serviceCharge).toFixed(2),
          },
          {
            Header: "Difference",
            accessor: (row) =>
              calcDifference(
                row.duration,
                row.totalCost,
                row.serviceCharge,
                user
              ),
          },
          {
            Header: "Edit",
            accessor: "edit",
            Cell: (props) =>
              PostService({
                index: props.row.index,
                dispatch: props.updateMyData,
                api: api.patchService,
                tableId: category._id,
                tableIndex,
                initialData: props.row.original,
                products,
              }),
          },
          {
            Header: "Delete",
            accessor: "delete",
            Cell: (props) =>
              DeleteCell({
                props,
                api: api.deleteService,
                tableId: category._id,
                tableIndex,
                label: "service",
                warning: true,
              }),
          },
          {
            Header: "Move",
          },
        ],
      },
    ],
    [category.categoryName, category._id, user, tableIndex, products]
  );

  return (
    <DnDTableComponent
      columns={columns}
      data={category.services}
      updateMyData={dispatch}
      tableIndex={tableIndex}
      dragTableIndex={dragTableIndex}
      dragRowIndex={dragRowIndex}
      moveRow={moveRow}
      tableId={category._id}
      updateRowIndexes={updateRowIndexes}
      name={category.categoryName}
      vat={user.vat}
      products={products}
    />
  );
};

export default ServicesTable;
