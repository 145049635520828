import React, { useState } from "react";

import { useForm } from "../../hooks";
import * as api from "../../api";
import { gs } from "../../stores";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const ChangeEmail = () => {
  const [emailsDontMatch, setEmailsDontMatch] = useState(false);
  const [oldEmail, setOldEmail] = useState(false);
  const [patchedEmail, setPatchedEmail] = useState(false);
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    email1: "",
    email2: "",
    password: "",
  });

  const handleChangeEmail = (e) => {
    e.preventDefault();
    setEmailsDontMatch(false);
    setInvalid(false);
    setMakingRequest(true);
    setOldEmail(false);
    setPatchedEmail(false);
    api
      .changeEmail(form.email1, form.email2, form.password)
      .then(() => {
        if (isMounted.current) {
          setMakingRequest(false);
          setPatchedEmail(true);
          setForm((c) => {
            return { email1: "", email2: "", password: "" };
          });
        }
      })
      .catch(({ response }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          setForm((c) => {
            return { email1: "", email2: "", password: "" };
          });
        }

        switch (response?.data?.msg) {
          case "You must provide a new email!":
            return isMounted.current && setOldEmail(true);
          case "Password incorrect!":
            return isMounted.current && setInvalid(true);
          case "Bad request!":
            return isMounted.current && setEmailsDontMatch(true);
          default:
            return (gs.err = {
              response,
              route: "/account",
            });
        }
      });
  };

  return (
    <Styles>
      <Form className="change-email-form" onSubmit={handleChangeEmail}>
        <Form.Group controlId="email-1-form">
          <Form.Label>Enter your new email</Form.Label>
          <Form.Control
            placeholder="New Email"
            type="email"
            value={form.email1}
            onChange={(e) => handleChange(e, "email1")}
            required
            autoComplete="on"
            isInvalid={emailsDontMatch || oldEmail}
            isValid={patchedEmail}
          />
        </Form.Group>
        <Form.Group controlId="email-2-form">
          <Form.Label>Confirm your new email</Form.Label>
          <Form.Control
            type="email"
            value={form.email2}
            onChange={(e) => handleChange(e, "email2")}
            required
            placeholder="Confirm Email"
            isInvalid={emailsDontMatch || oldEmail}
            autoComplete="off"
            isValid={patchedEmail}
          />
          <Form.Control.Feedback type="invalid">
            {emailsDontMatch && "Emails must be the same."}
            {oldEmail && "You must provide a new email."}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="password-form">
          <Form.Label>Enter your password</Form.Label>
          <Form.Control
            type="password"
            value={form.password}
            onChange={(e) => handleChange(e, "password")}
            required
            placeholder="Password"
            isInvalid={invalid}
            autoComplete="off"
            isValid={patchedEmail}
          />
          <Form.Control.Feedback type="invalid">
            Incorrect password.
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid">
            Your email has been successfully changed.
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          disabled={makingRequest}
        >
          Submit
        </Button>
      </Form>
    </Styles>
  );
};

const Styles = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 0 1 220px;
`;

export default ChangeEmail;
