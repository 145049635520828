import React, { useEffect, useState } from "react";

import * as api from "../../api";
import { useForm } from "../../hooks";
import { gs } from "../../stores";

import { Next, GoBack } from "../buttons";

import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Income = () => {
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    income: "",
  });
  const [income, setIncome] = useState(0);

  useEffect(() => {
    api
      .getUser()
      .then(({ data }) => {
        if (isMounted.current) return setIncome(data.user.income);
      })
      .catch(({ response }) => {
        return (gs.err = {
          response,
          route: "/income",
        });
      });
  }, [isMounted]);

  const handleIncome = (e) => {
    e.preventDefault();
    setInvalid(false);
    if (+form.income < 0 || isNaN(+form.income)) {
      return setInvalid(true);
    }

    const yearlyIncome = 12 * +form.income;

    if (+income !== +yearlyIncome) {
      setMakingRequest(true);
      return api
        .patchUser({ income: yearlyIncome })
        .then((user) => {
          if (isMounted.current) {
            setIncome(+user.income);
            setMakingRequest(false);
            setForm((c) => {
              c.income = "";
            });
          }
        })
        .catch(({ response }) => {
          return (gs.err = {
            response,
            route: "/income",
          });
        });
    }

    return setForm((c) => {
      c.income = "";
    });
  };

  if (!gs.isAuthenticated()) return gs.redirect();

  return (
    <Styles>
      <h2>Monthly Income</h2>
      <Form onSubmit={handleIncome}>
        <Form.Group controlId="income-goal-form">
          <Form.Label>
            <p>
              How much profit would you like to be left over with each{" "}
              <b>month</b>?
            </p>
            <p>
              (Remember to account for taxes, so this number should be higher
              than what you’d like your ‘take home’ profit to be!)
            </p>
            <p>
              If you’re unsure, check out my Income Goal Calculator{" "}
              <a href="https://bossyoursalon.calculators.cx/income-goal-calculator-byp">
                HERE
              </a>
              .
            </p>
          </Form.Label>
          <Form.Control
            placeholder="E.g. 2000.00"
            type="text"
            value={form.income}
            onChange={(e) => handleChange(e, "income")}
            required
            isInvalid={invalid}
            autoComplete="off"
          />
          <Form.Control.Feedback type="invalid">
            Income must be a positive number, no currency symbols or commas.
          </Form.Control.Feedback>
        </Form.Group>
        <Button type="submit" size="sm" disabled={makingRequest}>
          Submit
        </Button>
      </Form>
      <p className="income">
        Your desired monthly income: {(+income / 12).toFixed(2)}
      </p>
      <div className="footer">
        <GoBack route="/" />
        <Next route="/outgoings" />
      </div>
    </Styles>
  );
};

export default Income;

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;

  form {
    margin-top: 1rem;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .form-control {
      max-width: 300px;
    }

    .form-label {
      margin-bottom: 0;
    }

    .btn-primary {
      align-self: flex-start;
    }
  }

  .income {
    margin: 1rem 0rem 2rem 0rem;
    font-weight: bold;
  }

  .footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;
