import React, { useEffect, useState } from "react";

import uploadImage from "../../logos/add_photo_alternate_rounded.svg";

import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import Form from "react-bootstrap/Form";

const StyledFileUpload = styled.div`
  width: fit-content;

  .form-group {
    display: flex;
    flex-flow: column nowrap;

    div {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    img {
      max-width: 3rem;
      min-width: 3rem;
      max-height: 3rem;
      min-height: 3rem;

      margin-right: ${(props) => (props.table ? "0rem" : "0.5rem")};
      border-radius: 0.5rem;
    }

    :hover {
      cursor: pointer;
    }
  }
`;

const FileUpload = ({
  preview,
  setForm,
  dispatch,
  controlId,
  // Forces a refetch of the image and prevents caching
  srcHash,
  table = false,
  mobile = false,
  disabled = false,
}) => {
  const [previewFile, setPreviewFile] = useState(preview);
  const [invalidFile, setInvalidFile] = useState(false);

  const acceptedFiles = [".jpeg", ".png", ".avif", ".webp", ".svg"];

  useEffect(() => {
    setPreviewFile(preview);
  }, [preview]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        "PNG",
        50,
        0,
        (file) => {
          resolve(file);
        },
        "file"
      );
    });

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    onDrop: (acceptedFiles) => {
      setInvalidFile(false);

      resizeFile(acceptedFiles[0])
        .then((resizedFile) => {
          setForm(resizedFile, URL.createObjectURL(resizedFile));
        })
        .catch((err) => {
          if (err.message === "File Not Found!") {
            return setInvalidFile(true);
          }

          dispatch({
            type: "error",
            response: { status: err.status || 500, data: { msg: err.message } },
          });
        });
    },
    accept: {
      "image/jpeg": acceptedFiles,
    },
  });

  return (
    <StyledFileUpload table={table} {...getRootProps()}>
      <Form.Group controlId={controlId}>
        <Form.Label srOnly={!mobile && table}>
          Product Image (Optional)
        </Form.Label>
        <div>
          <input {...getInputProps()} />
          <img
            className="upload-img"
            src={
              (srcHash ? `${previewFile}?${srcHash}` : previewFile) ||
              uploadImage
            }
            alt={previewFile || "upload image"}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = uploadImage; // default image stored locally
            }}
          />
          {(!table || mobile) && <div>Drag or click to upload</div>}
        </div>
        {invalidFile && (
          <Form.Control.Feedback type="invalid">
            Supported files are{" "}
            {acceptedFiles.map((file, i) =>
              i === acceptedFiles.length - 1 ? file : `${file} `
            )}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </StyledFileUpload>
  );
};

export default FileUpload;
