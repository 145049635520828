export const mainTheme = {
  headerFontFam: "'Bebas Neue', cursive",
  fontFam: "'Raleway', sans-serif",
  htmlBg: "#ffffff",
  bg: "#ffffff",
  textC: "rgba(0, 0, 0, 0.8)",
  headerC: "rgba(33, 37, 41, 1)",
  bysTheme: "rgba(236, 0, 137, 1)",
  bysThemeTrans: "rgba(236, 0, 137, 0.5)",
  bysThemeTrans1: "rgba(236, 0, 137, 0.2)",
  bysThemeTrans2: "rgba(236, 0, 137, 0.1)",
  boxShadow: "0 0 0 0.2rem rgba(236, 0, 137, 0.5)",
  border: "1px solid #dee2e6",
  borderR: "0.25rem",
  loadingMargin: "2rem",
};
