import React from "react";

import { useForm } from "../../hooks";
import * as api from "../../api";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NoMarginP from "../styles/NoMarginP";

const PostStaff = ({ dispatch }) => {
  const {
    form,
    handleChange,
    setForm,
    isMounted,
    makingRequest,
    setMakingRequest,
  } = useForm({
    name: "",
  });

  const handlePostStaff = (e) => {
    setMakingRequest(true);
    e.preventDefault();

    api
      .postStaff({ name: form.name })
      .then((data) => {
        if (isMounted.current) {
          dispatch({ type: "add-data", data });
          setForm((c) => {
            c.name = "";
          });
          setMakingRequest(false);
        }
      })
      .catch(({ response }) => {
        dispatch({
          type: "error",
          response,
        });
      });
  };

  return (
    <Form onSubmit={handlePostStaff} className="post-staff-form">
      <Form.Group controlId="staff-form">
        <Form.Label>
          <p>
            This is where the calculator works out how to make the money needed
            in your business, so you can hit your income goals and cover your
            overheads.
          </p>
          <p>
            Who brings in revenue into your business? Add their names below.
          </p>
          <p>Work on your own? Then just add your name.</p>
          <NoMarginP>
            Have a team but some don’t bring in revenue? (E.g.
            assistants/receptionists), then <b>don’t</b> include them, but make
            sure their wages are added into your business outgoings page.
          </NoMarginP>
        </Form.Label>
        <Form.Control
          type="text"
          value={form.name}
          onChange={(e) => handleChange(e, "name")}
          required
          placeholder="Name. E.g. Maddi"
        />
      </Form.Group>
      <Button disabled={makingRequest} size="sm" type="submit">
        Add staff
      </Button>
    </Form>
  );
};

export default PostStaff;
