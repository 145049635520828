import React, { useMemo } from "react";

import * as api from "../../api";
import { calcOutgoingCost } from "../../utils";
import TableComponent from "../tables/TableComponent";
import {
  TextCell,
  DeleteCell,
  NumberCell,
  SelectCell,
} from "../tables/EditableCells";
import MobileTableComponent from "../tables/MobileTableComponent";

const OutgoingsTable = ({ dispatch, data, mobile }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Outgoing",
        accessor: "outgoingName",
        Cell: (props) => TextCell({ props, api: api.patchOutgoing, mobile }),
      },
      {
        Header: "Cost",
        accessor: "outgoingCost",
        Cell: (props) => NumberCell({ props, api: api.patchOutgoing, mobile }),
      },
      {
        Header: "Regularity",
        accessor: "regularity",
        Cell: (props) => SelectCell({ props, api: api.patchOutgoing, mobile }),
      },
      {
        Header: "Annual Cost",
        accessor: (row) =>
          calcOutgoingCost(row.outgoingCost, row.regularity).toFixed(2),
      },
      {
        Header: "Delete",
        accessor: "delete",
        Cell: (props) => DeleteCell({ props, api: api.deleteOutgoing, mobile }),
      },
    ],
    [mobile]
  );

  return mobile ? (
    <MobileTableComponent
      data={data}
      columns={columns}
      dispatch={dispatch}
      buttonTextFormatter={(outgoing) =>
        `${outgoing.outgoingName}: ${calcOutgoingCost(
          outgoing.outgoingCost,
          outgoing.regularity
        ).toFixed(2)} annual cost.`
      }
    />
  ) : (
    <TableComponent columns={columns} data={data} updateMyData={dispatch} />
  );
};

export default OutgoingsTable;
