import React, { useState } from "react";

import { gs } from "../../stores";

import Users from "./Users";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import DeleteAccount from "./DeleteAccount";

import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { observer } from "mobx-react";

const Account = observer(() => {
  const [changeEmail, setChangeEmail] = useState(true);
  const [changePassword, setChangePassword] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [users, setUsers] = useState(false);
  const [activeKey, setActiveKey] = useState("email");

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
    if (eventKey === "email") {
      setChangeEmail(true);
      setChangePassword(false);
      setDeleteAccount(false);
      setUsers(false);
    }

    if (eventKey === "password") {
      setChangeEmail(false);
      setChangePassword(true);
      setDeleteAccount(false);
      setUsers(false);
    }

    if (eventKey === "delete") {
      setChangeEmail(false);
      setChangePassword(false);
      setDeleteAccount(true);
      setUsers(false);
    }

    if (eventKey === "users") {
      setUsers(true);
      setChangeEmail(false);
      setChangePassword(false);
      setDeleteAccount(false);
    }
  };

  if (!gs.isAuthenticated()) return gs.redirect();

  return (
    <Styles>
      <Navbar expand="sm" id="navbar">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav onSelect={handleSelect} activeKey={activeKey}>
            <Nav.Link eventKey="email">Email</Nav.Link>
            <Nav.Link eventKey="password">Password</Nav.Link>
            <Nav.Link eventKey="delete">Delete</Nav.Link>
            {gs.admin && <Nav.Link eventKey="users">Users</Nav.Link>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="forms">
        {changeEmail && <ChangeEmail />}
        {changePassword && <ChangePassword />}
        {deleteAccount && <DeleteAccount />}
        {users && <Users />}
      </div>
    </Styles>
  );
});

export default Account;

const Styles = styled.div`
  display: flex;
  flex-flow: row nowrap;

  .navbar {
    flex: 0 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-self: flex-start;

    .navbar-nav {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
    }
  }

  .forms {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  @media (max-width: 575px) {
    flex-flow: column nowrap;

    .navbar {
      align-items: center;
      justify-content: space-between;
      flex-flow: row wrap;
    }
  }
`;
