import { useState, useEffect, useRef } from "react";

import { useImmer, useImmerReducer } from "use-immer";

// useForm returns a form and setter and changer.
// it also handles invalid and makingRequest boolean states.
export const useForm = (initialForm) => {
  const isMounted = useRef(true);
  const [form, setForm] = useImmer(initialForm);
  const [invalid, setInvalid] = useState(false);
  const [makingRequest, setMakingRequest] = useState(false);

  useEffect(() => {
    return () => {
      // component is no longer mounted
      isMounted.current = false;
    };
  }, []);

  const handleChange = (e, input) => {
    e.persist();
    setForm((c) => {
      c[input] = e.target.value;
    });
  };

  return {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  };
};

// useToggle returns atoggle that switches between true and false on set.
export const useToggle = () => {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle((toggle) => !toggle);
  };

  return [toggle, handleToggle];
};

// /**
//  * useDeepEffect only updates if the previous dependencies don't deep equal the new dependencies.
//  *
//  * prevents us updating when the dependencies memory address has changed but deep value hasn't.
//  *
//  * @param effectFn
//  * @param deps
//  */
// export const useDeepEffect = (effectFn, deps) => {
//   const prevDeps = useRef([]);

//   useEffect(() => {
//     const shouldUpdate =
//       !prevDeps.current.every((prev, i) => isEqual(prev, deps[i])) ||
//       prevDeps.current.length === 0;

//     if (shouldUpdate) effectFn();

//     prevDeps.current = deps;
//   }, [effectFn, ...deps]);
// };

// useInit allows us to initialise the state and set up a component.
// we return the state and a dispatch function to edit the state.
export const useInit = (reducer, initialState, ...handlers) => {
  // useImmerReducer allows us to mutate state for performance
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const isMounted = useRef(true);
  const handlersRef = useRef(handlers);

  useEffect(() => {
    return () => {
      // component is no longer mounted
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    handlersRef.current.forEach((handler) => {
      handler
        .api()
        .then(({ data }) => {
          // if the component is still mounted, set the data
          if (isMounted.current) {
            dispatch({ type: handler.type, data });
          }
        })
        .catch(({ response }) => {
          // api has returned an error
          return dispatch({
            type: "error",
            response,
          });
        });
    });
  }, [dispatch]);

  return [state, dispatch];
};
