import React from "react";

import * as api from "../../api";
import { gs } from "../../stores";
import { useInit } from "../../hooks";

import UsersTable from "./UsersTable";
import PostUser from "./PostUser";
import PatchUser from "./PatchUser";

import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const Users = () => {
  const [state, dispatch] = useInit(reducer, initialState, {
    api: api.getUsers,
    type: "set-data",
  });

  return (
    <Styles>
      {state.isLoading ? (
        <Spinner animation="border" className="spinner" />
      ) : (
        <UsersTable data={state.users} dispatch={dispatch} />
      )}
      <PostUser dispatch={dispatch} />
      <PatchUser dispatch={dispatch} />
    </Styles>
  );
};

export default Users;

const reducer = (state, action) => {
  switch (action.type) {
    case "set-data":
      state.users = action.data.users;
      state.isLoading = false;
      return;
    case "delete-data":
      state.users = state.users.filter((user, index) => index !== action.index);
      return;
    case "update-data-no-index":
      // we don't have access to index where this is called from, if you do,
      // use "update-data".
      state.users = state.users.map((user) => {
        if (user._id === action.user._id) {
          return action.user;
        }
        return user;
      });

      return;
    case "update-data":
      // can still use index to access as the rows are still index when paginated, so p2 starts with index 10, 11, 12 etc.
      state.users[action.index] = action.user;

      return;
    case "add-data":
      state.users = [{ ...action.user }, ...state.users];
      return;
    case "error":
      gs.err = {
        response: action.response,
        route: "/account",
      };
      return;
    default:
      return state;
  }
};

const initialState = {
  users: [],
  isLoading: true,
};

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 80%;

  .post-user-form {
    align-self: flex-start;

    .form-control {
      margin-right: 1rem;
    }
  }

  table > tbody > tr > td {
    vertical-align: middle;
  }

  .pagination {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 1rem;

    .pagination-buttons {
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 0.5rem;
    }

    .search-page-form {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .form-control {
        margin-left: 0.2rem;
      }
    }
  }

  @media (max-width: 575px) {
    width: 100%;
  }
`;
