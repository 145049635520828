import React from "react";

import { useForm } from "../../hooks";
import { gs } from "../../stores";
import * as api from "../../api";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const DeleteAccount = () => {
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    password: "",
  });

  const handleDeleteAccount = (e) => {
    e.preventDefault();
    setInvalid(false);
    setMakingRequest(true);
    api
      .deleteAccount(form.password)
      .then(() => {
        gs.logout();
      })
      .catch(({ response }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          setForm((c) => {
            return { password: "" };
          });
        }

        switch (response?.data?.msg) {
          case "Password incorrect!":
            return isMounted.current && setInvalid(true);
          default:
            return (gs.err = {
              response,
              route: "/account",
            });
        }
      });
  };

  return (
    <Styles>
      <Form className="change-email-form" onSubmit={handleDeleteAccount}>
        <Form.Group controlId="password-form">
          <Form.Label>
            Are you sure you want to delete your account? This action is
            irreversible. Enter your password and click delete if you still wish
            to delete your account.
          </Form.Label>
          <Form.Control
            type="password"
            value={form.password}
            onChange={(e) => handleChange(e, "password")}
            required
            placeholder="Password"
            isInvalid={invalid}
            autoComplete="off"
          />
          <Form.Control.Feedback type="invalid">
            Incorrect password.
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          type="submit"
          variant="danger"
          size="sm"
          disabled={makingRequest}
        >
          Delete
        </Button>
      </Form>
    </Styles>
  );
};

const Styles = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 0 1 220px;
`;

export default DeleteAccount;
