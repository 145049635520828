import React, { useState, useEffect, useRef } from "react";

import * as api from "../../api";

import Form from "react-bootstrap/Form";

const CategoryHeader = ({ initialName, dispatch, tableId, tableIndex }) => {
  const isMounted = useRef(true);
  // We need to keep and update the state of the cell normally
  const [categoryName, setCategoryName] = useState(initialName);

  const onChange = (e) => {
    setCategoryName(e.target.value);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // We'll only update the external data when the input is blurred

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setCategoryName(initialName);
  }, [initialName]);

  const handleText = (e) => {
    e.preventDefault();
    if (categoryName === "") {
      return setCategoryName(initialName);
    }

    if (initialName !== categoryName) {
      api
        .patchCategory({ categoryName }, tableId)
        .then(({ data }) => {
          if (isMounted.current) {
            return dispatch({
              type: "update-category",
              updatedCategory: data.category,
              tableIndex,
            });
          }
        })
        .catch(({ response }) => {
          return dispatch({
            type: "error",
            response,
          });
        });

      return;
    }
  };

  return (
    <Form onSubmit={handleText} className="category-form">
      <Form.Group controlId={`category-form-${tableId}`}>
        <Form.Label srOnly>Edit category name</Form.Label>
        <Form.Control
          required
          className="category-name-input table-input"
          size="sm"
          as="input"
          type="text"
          value={categoryName}
          onChange={onChange}
          onBlur={handleText}
        />
      </Form.Group>
    </Form>
  );
};

export default CategoryHeader;
