import React, { useState } from "react";

import { useForm } from "../../hooks";
import banner from "../../logos/bys-banner.png";
import * as api from "../../api";
import { gs } from "../../stores";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "@reach/router";
import styled from "styled-components";

const ResetPasswordEmail = () => {
  const {
    form,
    setForm,
    handleChange,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    email: "",
  });
  const [sentEmail, setSentEmail] = useState(false);
  const [email, setEmail] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    setMakingRequest(true);
    api
      .emailResetPassword(form.email)
      .then(() => {
        if (isMounted.current) {
          setEmail(form.email);
          setMakingRequest(false);
          setSentEmail(true);
          setForm((c) => {
            c.email = "";
          });
        }
      })
      .catch(({ response }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          setForm((c) => {
            return { email: "" };
          });
        }

        return (gs.err = {
          response,
          route: `/reset`,
        });
      });
  };

  if (gs.loggedIn) return gs.redirect();

  return (
    <Styles>
      <img src={banner} alt="Boss Your Salon Banner" width="100%" />
      <Form className="send-email-password-reset-form" onSubmit={sendEmail}>
        <Form.Group controlId="email-password-reset-form">
          <Form.Label>Email</Form.Label>
          <Form.Control
            placeholder="Email"
            type="email"
            value={form.email}
            onChange={(e) => handleChange(e, "email")}
            required
            isValid={sentEmail}
            disabled={sentEmail}
          />
          <Form.Control.Feedback type="valid">
            An email has been sent to {email}! If you can't find it make sure
            you check your spam!
          </Form.Control.Feedback>
          <Form.Text className="text-muted">
            Sending your email may take a minute. please wait for confirmation
            after submitting.
          </Form.Text>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          disabled={makingRequest || sentEmail}
        >
          Submit
        </Button>
        <Form.Group controlId="login-link">
          <Form.Text className="text-muted">
            <Link to="/login">Already know your password? Log in</Link>
          </Form.Text>
        </Form.Group>
      </Form>
    </Styles>
  );
};

export default ResetPasswordEmail;

const Styles = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  img {
    max-width: 450px;
  }

  .send-email-password-reset-form {
    margin-top: 1rem;
    max-width: 400px;
    width: 400px;
  }

  @media (max-width: 500px) {
    .send-email-password-reset-form {
      max-width: 222px;
      width: 222px;
      width: auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }
`;
