import React, { useMemo, useRef } from "react";

import { gs } from "../../stores";
import * as api from "../../api";

import { useDrag, useDrop } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripLines } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";

const DND_ITEM_TYPE = "row";

const DnDTableRow = observer(
  ({
    row,
    role,
    index,
    moveRow,
    tableIndex,
    stillDragging,
    updateMyData,
    tableId,
    vat,
  }) => {
    const dropRef = useRef(null);
    const dragRef = useRef(null);

    const [, drop] = useDrop({
      accept: DND_ITEM_TYPE,
      hover(item, monitor) {
        if (!dropRef.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;
        const oldTableIndex = item.tableIndex;
        const newTableIndex = tableIndex;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex && oldTableIndex === newTableIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // for end drag func
        // set pre move to stop any potential race conditions
        item.pushTableId = tableId;
        item.pushIndex = hoverIndex;

        // Time to actually perform the action
        moveRow(dragIndex, hoverIndex, oldTableIndex, newTableIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
        item.tableIndex = newTableIndex;
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      // original values passed into item are available in item in hover and end
      item: {
        type: DND_ITEM_TYPE,
        index,
        tableIndex,
        initialTableId: tableId,
        initialRowIndex: index,
        pushTableId: tableId,
        pushIndex: index,
      },
      end(item) {
        const pullIndex = item.initialRowIndex;
        const pushIndex = item.pushIndex;
        const pullTableId = item.initialTableId;
        const pushTableId = item.pushTableId;

        // nothing has moved, don't edit BE
        if (pullIndex === pushIndex && pullTableId === pushTableId) {
          updateMyData({
            type: "reset-drag",
          });

          return;
        }

        // make opacity 1 again on drop
        // set loading spinner for moving rows
        updateMyData({
          type: "update-be-reset-drag",
        });

        // update BE when category remains the same
        if (pullTableId === pushTableId) {
          api
            .moveService(pushTableId, pushIndex, pullIndex)
            .then(() => {
              // update row indexes to match new order
              // finish loading
              updateMyData({
                type: "end-dragging",
              });
            })
            .catch(({ response }) => {
              return updateMyData({
                type: "error",
                response,
              });
            });

          return;
        }

        // update BE when category is changed
        api
          .moveServiceCategory(pushTableId, pullTableId, pushIndex, pullIndex)
          .then(() => {
            // remove any empty categories and
            // update row indexes to match new order
            // finish loading
            updateMyData({
              type: "end-dragging",
            });
          })
          .catch(({ response }) => {
            return updateMyData({
              type: "error",
              response,
            });
          });

        return;
      },
      collect: (monitor) => ({
        // custom stillDragging bool to stop isDragging returning false when dragging across categories
        isDragging: monitor.isDragging() ? true : stillDragging,
      }),
    });

    // make row invisible when dragging
    const opacity = isDragging ? 0 : 1;

    preview(drop(dropRef));
    drag(dragRef);

    return useMemo(() => {
      return (
        <tr role={role} ref={dropRef} style={{ opacity }}>
          {row.cells.map((cell) => {
            if (cell.column.Header === "Move") {
              return gs.isTouch ? (
                // pass in a undraggable cell when on touch to stop html5 backend bug that
                // makes the oppacity of the row go to 0 if you hold your finger on the move cell.
                // we have an event listener to switch isTouch in App.js if a touch is regestered and switch to this undraggable cell.
                // can't pass in cell props when to this as the bug still happens if we do. So we pass in a custom key
                <td key={`touch-drag-and-drop-cell-${row.original._id}`}>
                  <FontAwesomeIcon icon={faGripLines} />
                </td>
              ) : (
                <td
                  ref={dragRef}
                  // need to pass this in to stop error for not having a unique key on each item.
                  // if we do a custom unique key, it bugs after we try to drag more than once
                  {...cell.getCellProps()}
                >
                  <FontAwesomeIcon icon={faGripLines} />
                </td>
              );
            }
            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
          })}
        </tr>
      );
    }, [row.original, role, vat, opacity, dropRef, dragRef, gs.isTouch]); // when we update the row in state, we spread the row we want to update, changing it's memory reference, therefore the reference of row.original. rerendering that row, but none of the ones that haven't been edited, this is very performant
    // using React.memo is difficult here. it doesn't seem to work with DnD, does for the first few but then bugs creep in
  }
);

export default DnDTableRow;
