import React from "react";

import styled from "styled-components";
import { useCookies } from "react-cookie";
import Button from "react-bootstrap/Button";
import { Link } from "@reach/router";

const SetPrivacyCookie = () => {
  const [cookies, setCookie] = useCookies(["acceptedPrivacyPolicy"]);

  return (
    <Styles>
      {cookies.acceptedPrivacyPolicy !== "true" && (
        <p>
          We use cookies to improve your experience on our site. To find out
          more, read our <Link to="/privacypolicy">privacy/cookie policy.</Link>{" "}
          <Button
            size="sm"
            onClick={() =>
              setCookie("acceptedPrivacyPolicy", true, {
                path: "/",
                maxAge: 60 * 60 * 24 * 365 * 1,
                sameSite: true,
              })
            }
          >
            OK
          </Button>
        </p>
      )}
    </Styles>
  );
};

export default SetPrivacyCookie;

const Styles = styled.div`
  grid-area: privacy;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: stretch;
  text-align: center;
  color: #fff;

  p {
    background-color: ${(props) => props.theme.bysTheme};
    margin: 0rem 0rem;
    padding: 0.5rem 0rem;
  }

  button {
    background-color: blue;
  }

  a {
    color: white;
    text-decoration: underline;
  }
`;
