import React, { useState } from "react";

import { useForm } from "../../hooks";
import banner from "../../logos/bys-banner.png";
import { gs } from "../../stores";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Link } from "@reach/router";
import styled from "styled-components";

const Login = () => {
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    email: "",
    password: "",
  });
  const [deactivated, setDeactivated] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    setMakingRequest(true);
    setInvalid(false);
    setDeactivated(false);

    gs.login(form.email, form.password).catch(({ response }) => {
      if (isMounted.current) {
        setMakingRequest(false);
        setForm((c) => {
          return { email: "", password: "" };
        });
      }

      switch (response?.data?.msg) {
        case "User account is deactivated!":
          return isMounted.current && setDeactivated(true);
        case "Invalid email or password!":
          return isMounted.current && setInvalid(true);
        default:
          return (gs.err = {
            response: response,
            route: "/login",
          });
      }
    });
  };

  if (gs.loggedIn) return gs.redirect();

  return (
    <Styles>
      <img src={banner} alt="Boss Your Salon Banner" width="100%" />
      <Form className="login-form" onSubmit={handleLogin}>
        <Form.Group controlId="login-email-form">
          <Form.Label>Email</Form.Label>
          <Form.Control
            placeholder="Email"
            type="email"
            value={form.email}
            onChange={(e) => handleChange(e, "email")}
            required
            isInvalid={invalid || deactivated}
          />
        </Form.Group>
        <Form.Group controlId="login-password-form">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={form.password}
            onChange={(e) => handleChange(e, "password")}
            required
            placeholder="Password"
            isInvalid={invalid || deactivated}
            autoComplete="on"
          />
          {invalid ? (
            <Form.Control.Feedback type="invalid">
              Oops! Something isn’t quite right. Please note that you have two
              logins - one for your online portal and one for this calculator.
              Please double check your welcome email and grab the password for
              the calculator. It’s case sensitive so copy & paste if you can!
            </Form.Control.Feedback>
          ) : (
            <Form.Control.Feedback type="invalid">
              Oops! Your subscription to Boss Your Profits has ended, contact
              maddi@bossyoursalon to reactivate!
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          disabled={makingRequest}
        >
          Log In
        </Button>
        <Form.Group controlId="reset-password-link">
          <Form.Text className="text-muted">
            <Link to="/reset">Forgotten your password?</Link>
          </Form.Text>
        </Form.Group>
      </Form>
    </Styles>
  );
};

export default Login;

const Styles = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  img {
    max-width: 450px;
  }

  .login-form {
    margin-top: 1rem;
    max-width: 400px;
    width: 400px;
  }

  @media (max-width: 500px) {
    .login-form {
      max-width: 222px;
      width: 222px;
      width: auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }
`;
