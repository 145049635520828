import React from "react";

import * as api from "../../api";
import { useForm } from "../../hooks";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const PostCategory = ({ dispatch }) => {
  const {
    form,
    handleChange,
    setForm,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    categoryName: "",
  });

  const handlePostCategory = (e) => {
    setMakingRequest(true);
    e.preventDefault();

    api
      .postCategory({ categoryName: form.categoryName })
      .then((category) => {
        if (isMounted.current) {
          dispatch({ type: "add-category", category });
          setForm((c) => {
            c.categoryName = "";
          });
          setMakingRequest(false);
        }
      })
      .catch(({ response }) => {
        dispatch({
          type: "error",
          response,
        });
      });
  };

  return (
    <Form
      onSubmit={handlePostCategory}
      className="post-category-form"
      inline
      size="sm"
    >
      <Form.Group controlId="category-form">
        <Form.Label srOnly>Add Category</Form.Label>
        <Form.Control
          type="text"
          value={form.categoryName}
          onChange={(e) => handleChange(e, "categoryName")}
          required
          placeholder="Category name. E.g. Nails"
        />
      </Form.Group>
      <Button disabled={makingRequest} size="sm" type="submit">
        Add category
      </Button>
    </Form>
  );
};

export default PostCategory;
