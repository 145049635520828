import React from "react";

import * as api from "../api";

import { decorate, observable } from "mobx";
import { navigate, Redirect } from "@reach/router";

export class State {
  constructor() {
    this.loggedIn = false;
    this.pChanged = null;
    this.admin = null;
    this.isMobile = false;
    this.isTouch = false;
    this.err = null;
  }

  login = (email, password) => {
    return api.postLogin(email, password).then((user) => {
      this.loggedIn = true;
      this.pChanged = user.pChanged;
      this.admin = user.admin;

      navigate("/");
    });
  };

  logout = () => {
    return api.getLogout().then(() => {
      this.loggedIn = false;
      this.pChanged = null;
      this.admin = null;
      navigate("/login");
    });
  };

  // setUserData logs the user in but doesn't redirect the user to the home page
  setUserData = (user) => {
    this.loggedIn = true;
    this.pChanged = user.pChanged;
    this.admin = user.admin;
  };

  // you need to check if the user is not authenticated and then return a Redirect in the component if they aren't. Otherwise we want to render the actual component, not a redirect.

  // isAuthenticated checks if the user is logged in or if it is their first time logging in. Returning true if they are logged in and it's not their first time.
  isAuthenticated = () => {
    if (this.pChanged === false) return false;

    if (!this.loggedIn) return false;

    return true;
  };

  // redirect redirects the user to the appropriate page depending on
  // their loggedIn or pChanged status.
  // ordered in importance so we can just call this function conditionally from any route.
  redirect = () => {
    // you need to be logged in to access the route
    if (!this.loggedIn) return <Redirect noThrow={true} to="/login" />;

    // you need to have changed your password to access the route
    if (this.pChanged === false)
      return <Redirect noThrow={true} to="/change-password" />;

    // only logged out or first time logged in members can use these routes
    if (this.pChanged || this.loggedIn)
      return <Redirect noThrow={true} to="/" />;
  };

  changedPassword = (pChanged) => {
    this.pChanged = pChanged;
    navigate("/");
  };
}

decorate(State, {
  loggedIn: observable,
  pChanged: observable,
  admin: observable,
  isMobile: observable,
  isTouch: observable,
  err: observable,
});

// global state
export const gs = new State();
