import React, { useState } from "react";

import { useForm } from "../../hooks";
import * as api from "../../api";
import { gs } from "../../stores";
import banner from "../../logos/bys-banner.png";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { Link, navigate } from "@reach/router";

const ResetPassword = ({ token }) => {
  const [invalidToken, setInvalidToken] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [patchedPassword, setPatchedPassword] = useState(false);
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    password1: "",
    password2: "",
    password: "",
  });

  const handleResetPassword = (e) => {
    e.preventDefault();
    setMakingRequest(true);
    setPatchedPassword(false);
    setInvalidToken(false);
    setInvalid(false);
    setPasswordsDontMatch(false);

    api
      .resetPassword(form.password1, form.password2, token)
      .then(() => {
        if (isMounted.current) {
          setPatchedPassword(true);
          setForm((c) => {
            return { password1: "", password2: "" };
          });

          navigate("/login");
        }
      })
      .catch(({ response }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          setForm((c) => {
            return { password1: "", password2: "" };
          });
        }

        switch (response?.data?.msg) {
          case "Invalid password!":
          case "Bad request!":
            return isMounted.current && setInvalid(true);
          case "Invalid or expired token":
            return isMounted.current && setInvalidToken(true);
          case "Passwords don't match!":
            return isMounted.current && setPasswordsDontMatch(true);
          default:
            return (gs.err = {
              response,
              route: `/reset/${token}`,
            });
        }
      });
  };

  if (gs.loggedIn) return gs.redirect();

  return (
    <Styles>
      <img src={banner} alt="Boss Your Salon Banner" width="100%" />
      <Form className="reset-password-form" onSubmit={handleResetPassword}>
        <Form.Group controlId="password-1-form">
          <Form.Label>Enter your new password</Form.Label>
          <Form.Control
            placeholder="New password"
            type="password"
            value={form.password1}
            onChange={(e) => handleChange(e, "password1")}
            required
            autoComplete="on"
            isInvalid={invalid || passwordsDontMatch || invalidToken}
            isValid={patchedPassword}
          />
        </Form.Group>
        <Form.Group controlId="reset-password-2-form">
          <Form.Label>Confirm your new password</Form.Label>
          <Form.Control
            type="password"
            value={form.password2}
            onChange={(e) => handleChange(e, "password2")}
            required
            placeholder="Confirm New Password"
            isInvalid={invalid || passwordsDontMatch || invalidToken}
            autoComplete="off"
            isValid={patchedPassword}
          />
          <Form.Control.Feedback type="invalid">
            {invalid &&
              "Password must be at least 6 characters long, contain one uppercase, one lower case, one number and no spaces. You cannot use an old password."}
            {passwordsDontMatch && "Passwords are not the same."}
            {invalidToken &&
              "Password reset token is invalid or has expired. Please request a new one to try again."}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid">
            Your password has been successfully changed.
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          disabled={makingRequest}
        >
          Submit
        </Button>
        <Form.Group controlId="login-link">
          <Form.Text className="text-muted">
            <Link to="/login">Already know your password? Log in</Link>
          </Form.Text>
        </Form.Group>
      </Form>
    </Styles>
  );
};

export default ResetPassword;

const Styles = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  img {
    max-width: 450px;
  }

  .reset-password-form {
    margin-top: 1rem;
    max-width: 400px;
    width: 400px;
  }

  @media (max-width: 500px) {
    .reset-password-form {
      max-width: 222px;
      width: 222px;
      width: auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }
`;
