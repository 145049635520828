import React, { useMemo } from "react";

import * as api from "../../api";

import { DeleteCell, DeactivateCell } from "../tables/EditableCells";
import FilterTableComponent from "../tables/FilterTableComponent";

const UsersTable = ({ dispatch, data }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Delete",
        accessor: "delete",
        Cell: (props) =>
          DeleteCell({
            props,
            api: api.deleteUserAdmin,
            label: "user",
            warning: true,
          }),
      },
      {
        Header: "Deactivated",
        accessor: "deactivated",
        Cell: (props) => DeactivateCell({ props, api: api.patchUserAdmin }),
      },
    ],
    []
  );

  return (
    <FilterTableComponent
      columns={columns}
      data={data}
      updateMyData={dispatch}
    />
  );
};

export default UsersTable;
