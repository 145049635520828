import React, { useCallback } from "react";

import DnDTableRow from "./DnDTableRow";
import DeleteCategory from "../services/DeleteCategory";
import PostService from "../services/PostService";
import CategoryHeader from "../services/CategoryHeader";

import * as api from "../../api";

import { useTable } from "react-table";
import Table from "react-bootstrap/Table";

function DnDTableComponent({
  columns,
  data,
  updateMyData,
  tableIndex,
  dragTableIndex,
  dragRowIndex,
  moveRow,
  tableId,
  updateRowIndexes,
  name,
  vat,
  products,
}) {
  const getRowId = useCallback(
    (row) => {
      return data.indexOf(row);
    },
    // passing data into here makes it recalculate the index whenever we update state above (such as when moveRows func is called), which makes isDragging in TableRow lose the correct drag index
    // by triggering updateRowIndexes in state, we can trigger when we want the indexes to be calculated manually, removing bugs.
    // we update indexes when we add or remove a service ( or remove a category with services in it)
    // and also when a drag sequence has finished, only if a row has moved during the drag sequence
    // if we update during a drag sequence, the isDragging gets out of sync with the UI.
    [updateRowIndexes]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      updateMyData,
      getRowId,
    });

  // grey out a category that is to be deleted on drag
  // once empty, can't drag services back into a category, so we grey out and then delete on ending drag sequence
  const opacity = data.length ? 1 : 0.5;

  return (
    <Table
      {...getTableProps()}
      className="desktop-table"
      responsive="sm"
      striped
      bordered
      style={{ opacity }}
    >
      <thead>
        {headerGroups.map((headerGroup, i) =>
          i === 0 ? (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {/* we can add custom cells to table headers in here */}
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {/* if we set the th with a className, then the header doesn't span all columns */}
                  <div className="category-table-header">
                    <CategoryHeader
                      initialName={column.render("Header")}
                      dispatch={updateMyData}
                      tableIndex={tableIndex}
                      tableId={tableId}
                    />
                    <div className="post-service-delete-category">
                      <PostService
                        dispatch={updateMyData}
                        tableId={tableId}
                        tableIndex={tableIndex}
                        products={products}
                        api={api.postService}
                      />
                      <DeleteCategory
                        name={name}
                        tableId={tableId}
                        dispatch={updateMyData}
                        tableIndex={tableIndex}
                      />
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ) : (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          )
        )}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(
          (row, i) =>
            prepareRow(row) || (
              <DnDTableRow
                row={row}
                index={i}
                moveRow={moveRow}
                tableIndex={tableIndex}
                stillDragging={
                  tableIndex === dragTableIndex && i === dragRowIndex
                }
                updateMyData={updateMyData}
                tableId={tableId}
                vat={vat}
                {...row.getRowProps()}
              />
            )
        )}
      </tbody>
    </Table>
  );
}

export default DnDTableComponent;
