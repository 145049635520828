import React from "react";

import * as api from "../../api";
import { gs } from "../../stores";
import { useInit } from "../../hooks";

import { Next, GoBack, Help } from "../buttons/";
import Vat from "./Vat";
import ServicesTable from "./ServicesTable";
import MobileServiceTable from "./MobileServiceTable";
import PostCategory from "./PostCategory";

import { observer } from "mobx-react";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import update from "immutability-helper";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

const Services = observer(() => {
  const [state, dispatch] = useInit(reducer, initialState, {
    api: api.getCategories,
    type: "set-data",
  });

  const moveRow = (dragIndex, hoverIndex, tableIndex, newTableIndex) => {
    const dragRow = state.categories[tableIndex].services[dragIndex];

    // staying in the same category
    if (tableIndex === newTableIndex) {
      dispatch({
        type: "move-row",
        tableIndex,
        newTableIndex,
        hoverIndex,
        dragRow,
        dragIndex,
      });

      return;
    }

    // moving to another category
    dispatch({
      type: "move-table",
      tableIndex,
      newTableIndex,
      hoverIndex,
      dragRow,
      dragIndex,
    });
  };

  // reduce opacity when updating moved rows in BE after ending drag
  const opacity = state.movingRows ? 0.5 : 1;

  if (!gs.isAuthenticated()) return gs.redirect();

  return (
    <Styles isMobile={gs.isMobile}>
      {state.movingRows && (
        <Spinner animation="border" className="moving-rows-spinner" />
      )}
      {/*disabled fieldsets disable all form elements inside them, unlike divs*/}
      <fieldset
        // disabled={state.movingRows} // looks broken, don't disbale whilst moving
        style={{ opacity }}
        className="services-fieldset"
      >
        <div className="header-help">
          <h2>Services</h2>
          <Help bodyKey={"services"} />
        </div>
        <p className="services-description">
          Use the table below to add each service you offer. We recommend using
          the ‘categories’ feature to separate your services (otherwise, they
          will default to alphabetical order).
        </p>
        <ul>
          <li>
            ‘RRP’ is the correct pricing, based on what you have input
            throughout the calculator
          </li>
          <li>
            ‘Gross Profit’ is the total profit you would make per service, if
            you charge the RRP (remember, this is gross so taxes/insurance may
            need to be paid from this figure)
          </li>
          <li>
            ‘Current Price’ is where you can add in what you currently charge
            for each service
          </li>
          <li>
            ‘Difference’ will show you the difference in the service cost to
            your client, between your current pricing, and the RRP correct
            pricing. If it’s a minus figure, this is what you’re currently
            undercharging by. If it’s a positive figure, don’t drop your prices!
            It just means this service is extra profitable, woohoo!)
          </li>
        </ul>
        <Vat dispatch={dispatch} vat={state.user.vat} />
        {state.isLoading ? (
          <Spinner animation="border" className="spinner" />
        ) : (
          <>
            {gs.isMobile ? (
              state.categories.map((category, i) => {
                return (
                  <MobileServiceTable
                    dispatch={dispatch}
                    category={category}
                    tableIndex={i}
                    key={category._id}
                    user={state.user}
                    products={state.products}
                  />
                );
              })
            ) : (
              <DndProvider backend={HTML5Backend}>
                {state.categories.map((category, i) => {
                  return (
                    <ServicesTable
                      category={category}
                      dispatch={dispatch}
                      tableIndex={i}
                      dragTableIndex={state.dragTableIndex}
                      dragRowIndex={state.dragRowIndex}
                      moveRow={moveRow}
                      updateRowIndexes={state.updateRowIndexes}
                      user={state.user}
                      key={category._id}
                      products={state.products}
                    />
                  );
                })}
              </DndProvider>
            )}
          </>
        )}
        <PostCategory dispatch={dispatch} />
        <div className="footer">
          <GoBack route="/products" />
          <Next route="/overview" />
        </div>
      </fieldset>
    </Styles>
  );
});

const reducer = (state, action) => {
  switch (action.type) {
    case "set-data":
      state.categories = action.data.categories;
      state.products = action.data.products;
      state.user = action.data.user;
      state.isLoading = false;

      return;
    case "delete-data":
      // update row indexes in TableComponent so getRowId can access correct rows
      state.updateRowIndexes = !state.updateRowIndexes;

      // if deleting last service, then remove entire category
      if (state.categories[action.tableIndex].services.length === 1) {
        state.categories = update(state.categories, {
          $splice: [[action.tableIndex, 1]],
        });

        return;
      }

      // remove service
      state.categories = update(state.categories, {
        [action.tableIndex]: {
          services: {
            $splice: [[action.index, 1]],
          },
        },
      });
      return;
    case "delete-category":
      // update row indexes in TableComponent so getRowId can access correct rows
      state.updateRowIndexes = !state.updateRowIndexes;

      // remove category and all associated services
      state.categories = update(state.categories, {
        $splice: [[action.tableIndex, 1]],
      });
      return;
    case "add-category":
      // add category
      state.categories = [...state.categories, action.category];

      // update row indexes in TableComponent so getRowId can access correct rows
      state.updateRowIndexes = !state.updateRowIndexes;
      return;
    case "add-data":
      // add service
      state.categories = update(state.categories, {
        [action.tableIndex]: {
          services: {
            $push: [action.data.service],
          },
        },
      });

      // update row indexes in TableComponent so getRowId can access correct rows
      state.updateRowIndexes = !state.updateRowIndexes;
      return;
    case "update-data":
      // update service
      state.categories = update(state.categories, {
        [action.tableIndex]: {
          services: {
            [action.index]: {
              $set: action.data.service,
            },
          },
        },
      });

      // update row indexes in TableComponent so getRowId can access correct rows
      // if you don't recalc indexes when updating a row, the updated row gets a -1 index (not found? And then dissapears on the next render)
      state.updateRowIndexes = !state.updateRowIndexes;
      return;
    case "update-category":
      // still need to update indexes, since we are resetting services too.
      state.updateRowIndexes = !state.updateRowIndexes;

      // update category
      // we have to reset the whole category when updating the name, otherwise bugs occur
      state.categories = update(state.categories, {
        [action.tableIndex]: {
          $set: action.updatedCategory,
        },
      });
      return;
    case "move-row":
      // set these in state to keep opacity of dragged row at 0 until dropped
      // when dragged across categories
      state.dragTableIndex = action.newTableIndex;
      state.dragRowIndex = action.hoverIndex;

      // delete from old index and insert in new index
      state.categories = update(state.categories, {
        [action.tableIndex]: {
          services: {
            $splice: [
              [action.dragIndex, 1],
              [action.hoverIndex, 0, action.dragRow],
            ],
          },
        },
      });

      return;
    case "move-table":
      // set these to keep isDragging in TableRow up to date, even when switching category
      state.dragTableIndex = action.newTableIndex;
      state.dragRowIndex = action.hoverIndex;

      // delete one from old category, add dragged row to new category
      state.categories = update(state.categories, {
        [action.tableIndex]: {
          services: {
            $splice: [[action.dragIndex, 1]],
          },
        },
        [action.newTableIndex]: {
          services: {
            $splice: [[action.hoverIndex, 0, action.dragRow]],
          },
        },
      });

      return;
    case "reset-drag":
      // reset isDragging to false in TableRow
      state.dragTableIndex = null;
      state.dragRowIndex = null;
      return;
    case "update-be-reset-drag":
      // reset isDragging to false in TableRow
      state.dragTableIndex = null;
      state.dragRowIndex = null;
      // set moving rows to true whilst updating BE
      state.movingRows = true;
      return;
    case "end-dragging":
      // delete category if it has no services
      // can't drag any services back into an empty category
      state.categories = state.categories.filter(
        (category) => category.services.length !== 0
      );

      // finished moving rows, BE updated
      state.movingRows = false;
      // recalculate indexes
      // update indexes here, bugs when we update in "update-be-reset-drag".
      state.updateRowIndexes = !state.updateRowIndexes;
      return;
    case "update-user":
      state.user = action.user;

      return;
    case "error":
      gs.err = {
        response: action.response,
        route: "/services",
      };

      return;
    default:
      return state;
  }
};

const initialState = {
  // data
  categories: [],
  // initial load for getting data
  isLoading: true,
  // index of the category of the table row being dragged
  dragTableIndex: null,
  // index of the table row being dragged
  dragRowIndex: null,
  // updating moved rows in backend
  movingRows: false,
  // toggle to update the indexes of rows in TableComponent, do this whenever adding/deleting a row or category, also after ending a drag sequence with moved rows
  updateRowIndexes: true,
  user: {
    vat: null,
    hourlyTotal: null,
  },
};

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .services-fieldset {
    display: flex;
    flex-flow: column nowrap;
  }

  .moving-rows-spinner {
    z-index: 10;
    position: fixed;
    margin-top: 35vh;
    align-self: center;
  }

  .header-help {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .services-description {
    margin-top: 1rem;
  }

  .patch-vat-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .form-control {
      max-width: 300px;
    }
  }

  .vat {
    margin: 1rem 0 2rem 0;
    font-weight: bold;
  }

  .category-table-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => (props.isMobile ? "0.5rem" : "0rem")};
  }

  .category-form {
    .form-group {
      margin-bottom: 0rem;
      .category-name-input {
        font-size: 1.2rem;
        font-weight: bold;
        color: #212529;
      }
    }
  }

  .post-service-delete-category {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    button {
      margin-left: 0.5rem;
    }
  }

  .edit-delete-service {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    button {
      margin-right: 0.5rem;
    }
  }

  .post-category-form {
    .form-control {
      margin-right: 1rem;
    }
  }

  .footer {
    margin-top: 2rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .mobile-services {
    margin-bottom: 1rem;
  }

  @media (max-width: 575px) {
    .post-category-form {
      display: flex;
      flex-flow: column nowrap;
      align-self: flex-start;

      .btn-primary {
        align-self: flex-start;
      }

      .form-control {
        margin-right: 0rem;
      }
    }
  }
`;

export default Services;
