import React from "react";

import { useForm } from "../../hooks";
import * as api from "../../api";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const PostOutgoing = ({ dispatch }) => {
  const {
    form,
    handleChange,
    setForm,
    isMounted,
    makingRequest,
    setMakingRequest,
  } = useForm({
    outgoingName: "",
  });

  const handlePostOutgoing = (e) => {
    setMakingRequest(true);
    e.preventDefault();

    api
      .postOutgoing({ outgoingName: form.outgoingName })
      .then((outgoing) => {
        if (isMounted.current) {
          dispatch({ type: "add-data", outgoing });
          setForm((c) => {
            c.outgoingName = "";
          });
          setMakingRequest(false);
        }
      })
      .catch(({ response }) => {
        dispatch({
          type: "error",
          response,
        });
      });
  };

  return (
    <Form
      onSubmit={handlePostOutgoing}
      className="post-outgoing-form"
      inline
      size="sm"
    >
      <Form.Group controlId="outgoingForm">
        <Form.Label srOnly>Outgoing</Form.Label>
        <Form.Control
          type="text"
          value={form.outgoingName}
          onChange={(e) => handleChange(e, "outgoingName")}
          required
          placeholder="Outgoing name. E.g. Rent"
        />
      </Form.Group>
      <Button disabled={makingRequest} size="sm" type="submit">
        Add outgoing
      </Button>
    </Form>
  );
};

export default PostOutgoing;
