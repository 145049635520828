import React, { useState } from "react";

import { useForm } from "../../hooks";
import * as api from "../../api";
import { gs } from "../../stores";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const PatchUser = ({ dispatch }) => {
  const [patchedEmail, setPatchedEmail] = useState(false);
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    email: "",
    newEmail: "",
  });

  const handleChangeEmail = (e) => {
    e.preventDefault();

    if (form.email === form.newEmail) return;

    setInvalid(false);
    setMakingRequest(true);
    setPatchedEmail(false);

    api
      .patchUserAdmin(form)
      .then(({ data }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          setPatchedEmail(true);
          setForm((c) => {
            return { email: "", newEmail: "" };
          });
          dispatch({
            type: "update-data-no-index",
            user: data.user,
          });
        }
      })
      .catch(({ response }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          setForm((c) => {
            return { email: "", newEmail: "" };
          });
        }

        switch (response?.data?.msg) {
          case "Requested data not found!":
          case "Data already exists!":
          case "Unauthorised!":
            return isMounted.current && setInvalid(true);
          default:
            return (gs.err = {
              response,
              route: "/account",
            });
        }
      });
  };

  return (
    <Styles>
      <Form className="patch-user-email-form" onSubmit={handleChangeEmail}>
        <Form.Group controlId="email-1-form">
          <Form.Label>Edit user email</Form.Label>
          <Form.Control
            placeholder="Email"
            type="email"
            value={form.email}
            onChange={(e) => handleChange(e, "email")}
            required
            autoComplete="on"
            isInvalid={invalid}
            isValid={patchedEmail}
          />
        </Form.Group>
        <Form.Group controlId="email-2-form">
          <Form.Label>New email</Form.Label>
          <Form.Control
            type="email"
            value={form.newEmail}
            onChange={(e) => handleChange(e, "newEmail")}
            required
            placeholder="New Email"
            isInvalid={invalid}
            autoComplete="off"
            isValid={patchedEmail}
          />
          <Form.Control.Feedback type="invalid">
            Either that email doesn't exist, the new email already exists or the
            email you are trying to change is an admin.
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          disabled={makingRequest}
        >
          Change email
        </Button>
      </Form>
    </Styles>
  );
};

const Styles = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  flex: 0 1 220px;
  margin-top: 1rem;
`;

export default PatchUser;
