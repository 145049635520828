import React, { useState } from "react";

import * as api from "../../api";
import banner from "../../logos/bys-banner.png";
import { gs } from "../../stores";
import { useForm } from "../../hooks";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const ForceChangePassword = () => {
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    password1: "",
    password2: "",
  });
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);

  const handleChangePassword = (e) => {
    e.preventDefault();
    setInvalid(false);
    setPasswordsDontMatch(false);
    setMakingRequest(true);
    api
      .forceChangePassword(form.password1, form.password2)
      .then((user) => {
        if (isMounted.current) gs.changedPassword(user);
      })
      .catch(({ response }) => {
        setMakingRequest(false);
        setForm((c) => {
          return { password1: "", password2: "" };
        });

        switch (response?.data?.msg) {
          case "Passwords don't match!":
            return isMounted.current && setPasswordsDontMatch(true);
          case "Invalid password!":
            return isMounted.current && setInvalid(true);
          default:
            return (gs.err = {
              response,
              route: "/change-password",
            });
        }
      });
  };

  if (gs.pChanged || !gs.loggedIn) return gs.redirect();

  return (
    <Styles>
      <img src={banner} alt="Boss Your Salon Banner" width="100%" />
      <p>
        Please enter and confirm your new password to use from here onwards.
      </p>
      <Form className="change-password-form" onSubmit={handleChangePassword}>
        <Form.Group controlId="password-1-form">
          <Form.Label>Enter your new password</Form.Label>
          <Form.Control
            placeholder="New password"
            type="password"
            value={form.password1}
            onChange={(e) => handleChange(e, "password1")}
            required
            autoComplete="on"
            isInvalid={invalid}
          />
        </Form.Group>
        <Form.Group controlId="password-2-form">
          <Form.Label>Confirm your new password</Form.Label>
          <Form.Control
            type="password"
            value={form.password2}
            onChange={(e) => handleChange(e, "password2")}
            required
            placeholder="Confirm Password"
            isInvalid={invalid || passwordsDontMatch}
            autoComplete="on"
          />
          <Form.Control.Feedback type="invalid">
            {invalid &&
              "Password must be at least 6 characters long, contain one uppercase, one lower case, one number and no spaces. You also cannot use your previous password."}
            {passwordsDontMatch && "Passwords must be the same."}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          disabled={makingRequest}
        >
          Submit
        </Button>
      </Form>
    </Styles>
  );
};

export default ForceChangePassword;

const Styles = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  img {
    max-width: 450px;
  }

  p {
    max-width: 400px;
    margin: 1rem 0rem 0rem 0rem;
  }

  .change-password-form {
    margin-top: 1rem;
    max-width: 400px;
    width: 400px;
  }

  @media (max-width: 500px) {
    .change-password-form {
      max-width: auto;
      width: auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }
`;
