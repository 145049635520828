import React, { useState } from "react";

import { useForm } from "../../hooks";
import * as api from "../../api";
import { gs } from "../../stores";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  const [patchedPassword, setPatchedPassword] = useState(false);
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    password1: "",
    password2: "",
    password: "",
  });

  const handleChangePassword = (e) => {
    e.preventDefault();
    setOldPassword(false);
    setMakingRequest(true);
    setPatchedPassword(false);
    setWrongPassword(false);
    setInvalid(false);
    setPasswordsDontMatch(false);

    api
      .changePassword(form.password1, form.password2, form.password)
      .then(() => {
        if (isMounted.current) {
          setMakingRequest(false);
          setPatchedPassword(true);
          setForm((c) => {
            return { password1: "", password2: "", password: "" };
          });
        }
      })
      .catch(({ response }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          setForm((c) => {
            return { password1: "", password2: "", password: "" };
          });
        }

        switch (response?.data?.msg) {
          case "Password incorrect!":
            return isMounted.current && setWrongPassword(true);
          case "You must provide a new password!":
            return isMounted.current && setOldPassword(true);
          case "Invalid password!":
            return isMounted.current && setInvalid(true);
          case "Bad request!":
            return isMounted.current && setPasswordsDontMatch(true);
          default:
            return (gs.err = {
              response,
              route: "/account",
            });
        }
      });
  };

  return (
    <Styles>
      <Form className="change-password-form" onSubmit={handleChangePassword}>
        <Form.Group controlId="password-1-form">
          <Form.Label>Enter your new password</Form.Label>
          <Form.Control
            placeholder="New password"
            type="password"
            value={form.password1}
            onChange={(e) => handleChange(e, "password1")}
            required
            autoComplete="on"
            isInvalid={invalid || oldPassword || passwordsDontMatch}
            isValid={patchedPassword}
          />
        </Form.Group>
        <Form.Group controlId="password-2-form">
          <Form.Label>Confirm your new password</Form.Label>
          <Form.Control
            type="password"
            value={form.password2}
            onChange={(e) => handleChange(e, "password2")}
            required
            placeholder="Confirm New Password"
            isInvalid={invalid || oldPassword || passwordsDontMatch}
            autoComplete="off"
            isValid={patchedPassword}
          />
          <Form.Control.Feedback type="invalid">
            {invalid &&
              "Password must be at least 6 characters long, contain one uppercase, one lower case, one number and no spaces."}
            {oldPassword && "You must provide a new password."}
            {passwordsDontMatch && "Passwords are not the same."}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="password-form">
          <Form.Label>Enter your password</Form.Label>
          <Form.Control
            type="password"
            value={form.password}
            onChange={(e) => handleChange(e, "password")}
            required
            placeholder="Password"
            isInvalid={wrongPassword}
            autoComplete="off"
            isValid={patchedPassword}
          />
          <Form.Control.Feedback type="invalid">
            Incorrect password.
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid">
            Your password has been successfully changed.
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          disabled={makingRequest}
        >
          Submit
        </Button>
      </Form>
    </Styles>
  );
};

const Styles = styled.main`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 0 1 220px;
`;

export default ChangePassword;
