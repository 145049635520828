import React, { useState, useEffect, useRef } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { useToggle } from "../../hooks";
import SelectOptions from "./SelectOptions";
import FileUpload from "../common/FileUpload";
import FlexRow from "../styles/FlexRow";

const imageUrl = process.env.REACT_APP_IMAGES_HOST;

// Create an editable cell renderer
export const TextCell = ({
  // aliasing value as initialValue so we can use the variabale name value below
  props: {
    value: initialValue,
    row: {
      index,
      original: { _id },
    },
    column: { id, Header },
    updateMyData,
  },
  api,
  tableIndex,
  mobile = false,
  disabled = false,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const [invalidValue, setInvalidValue] = useState(false);
  const [makingRequest, setMakingRequest] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleText = (e) => {
    e.preventDefault();
    setInvalidValue(false);

    if (value === "") {
      return setInvalidValue(true);
    }

    if (initialValue !== value) {
      setMakingRequest(true);
      api({ [id]: value }, _id)
        .then(({ data }) => {
          if (isMounted.current) {
            setMakingRequest(false);
            return updateMyData({
              type: "update-data",
              index,
              data,
              tableIndex,
            });
          }
        })
        .catch(({ response }) => {
          return updateMyData({
            type: "error",
            response,
          });
        });

      return;
    }
  };

  return (
    <Form onSubmit={handleText} className={!mobile && "table-form"}>
      <Form.Group controlId={`${id}-form-${_id}`} className="position-relative">
        <Form.Label srOnly={!mobile}>{Header}</Form.Label>
        <Form.Control
          required
          className={!mobile && "table-input"}
          size="sm"
          as="input"
          type="text"
          value={value}
          onChange={onChange}
          onBlur={handleText}
          disabled={makingRequest || disabled}
          isInvalid={invalidValue}
        />
        <Form.Control.Feedback type="invalid" tooltip={!mobile}>
          {`${Header} must not be empty`}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export const NumberCell = ({
  props: {
    value: initialValue,
    row: {
      index,
      original: { _id },
    },
    column: { id, Header },
    updateMyData,
  },
  api,
  fixed = 2,
  tableIndex,
  min,
  max,
  minFeedback,
  maxFeedback,
  percentage = false,
  mobile = false,
  disabled = false,
}) => {
  const [value, setValue] = useState((+initialValue).toFixed(fixed));
  const [invalidValue, setInvalidValue] = useState(false);
  const [valueHigh, setValueHigh] = useState(false);
  const [valueLow, setValueLow] = useState(false);
  const [makingRequest, setMakingRequest] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    setValue((+initialValue).toFixed(fixed));
  }, [initialValue, fixed]);

  const handleNumber = (e) => {
    e.preventDefault();

    setInvalidValue(false);
    setValueHigh(false);
    setValueLow(false);

    if (percentage && +value > 100) {
      return setInvalidValue(true);
    }

    if (value === "" || +value < 0 || isNaN(+value)) {
      return setInvalidValue(true);
    }

    if (min != undefined && +value < min) {
      return setValueLow(true);
    }

    if (max != undefined && +value > max) {
      return setValueHigh(true);
    }

    if (+initialValue !== +value) {
      setMakingRequest(true);
      api({ [id]: +value }, _id)
        .then(({ data }) => {
          if (isMounted.current) {
            setMakingRequest(false);
            return updateMyData({
              type: "update-data",
              index,
              data,
              tableIndex,
            });
          }
        })
        .catch(({ response }) => {
          return updateMyData({
            type: "error",
            response,
          });
        });
    }
    return;
  };

  const getFeedback = () => {
    if (valueHigh)
      return `${Header} must be less than or equal to ${
        maxFeedback ? maxFeedback : max
      }`;
    if (valueLow)
      return `${Header} must be more than or equal to ${
        minFeedback ? minFeedback : min
      }`;

    return percentage
      ? `${Header} can only be a number between 0 and 100`
      : `${Header} must be a positive number, no currency symbols or commas`;
  };

  return (
    <Form onSubmit={handleNumber} className={!mobile && "table-form"}>
      <Form.Group controlId={`${id}-form-${_id}`} className="position-relative">
        <Form.Label srOnly={!mobile}>{Header}</Form.Label>
        <Form.Control
          className={!mobile && "number-input table-input"}
          required
          size="sm"
          as="input"
          type="text"
          value={value}
          onChange={onChange}
          onBlur={handleNumber}
          disabled={makingRequest || disabled}
          isInvalid={invalidValue || valueLow || valueHigh}
        />
        <Form.Control.Feedback type="invalid" tooltip={!mobile}>
          {getFeedback()}
        </Form.Control.Feedback>
      </Form.Group>
    </Form>
  );
};

export const SelectCell = ({
  props: {
    value: initialValue,
    row: {
      index,
      original: { _id },
    },
    column: { id, Header },
    updateMyData,
  },
  api,
  tableIndex,
  mobile = false,
  number = true,
  disabled = false,
}) => {
  const [value, setValue] = useState(initialValue);
  const [makingRequest, setMakingRequest] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSelect = (e) => {
    setMakingRequest(true);
    api({ [id]: number ? +e.target.value : e.target.value }, _id)
      .then(({ data }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          return updateMyData({
            type: "update-data",
            index,
            data,
            tableIndex,
          });
        }
      })
      .catch(({ response }) => {
        return updateMyData({
          type: "error",
          response,
        });
      });

    return;
  };

  return (
    <Form className={!mobile && "table-form"}>
      <Form.Group controlId={`${id}-form-${_id}`}>
        <Form.Label srOnly={!mobile}>{Header}</Form.Label>
        <Form.Control
          as="select"
          size="sm"
          onChange={handleSelect}
          value={number ? +value : value}
          disabled={makingRequest || disabled}
        >
          <SelectOptions id={id} />
        </Form.Control>
      </Form.Group>
    </Form>
  );
};

export const DeleteCell = ({
  props: {
    row: {
      index,
      original: { _id, superAdmin },
    },
    updateMyData,
  },
  api,
  tableId,
  tableIndex,
  label,
  warning = false,
  mobile = false,
}) => {
  const [deleting, handleDeleting] = useToggle(false);
  const [makingRequest, setMakingRequest] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onClick = () => {
    setMakingRequest(true);
    api(_id, tableId)
      .then(({ data }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          handleDeleting();
          return updateMyData({
            type: "delete-data",
            index,
            tableIndex,
            data,
          });
        }
      })
      .catch(({ response }) => {
        return updateMyData({
          type: "error",
          response,
        });
      });
    return;
  };

  // no warning message on deletion
  // if this is a user cell, we don't want to be able to delete superAdmin accounts
  if (!warning || superAdmin) {
    return (
      <Button
        variant="danger"
        size="sm"
        onClick={onClick}
        disabled={makingRequest || superAdmin}
      >
        {mobile ? "Delete" : <FontAwesomeIcon icon={faTrash} />}
      </Button>
    );
  }

  // warning message on deletion
  return (
    <div>
      <Modal
        show={deleting}
        onHide={handleDeleting}
        centered
        aria-labelledby="contained-modal-title-center"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Delete ${label}?!`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure you want to delete this ${label}? This action cannot be
            undone!`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            size="sm"
            onClick={onClick}
            disabled={makingRequest}
          >
            Delete
          </Button>
          <Button variant="secondary" onClick={handleDeleting} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {!deleting && (
        <Button
          variant="danger"
          size="sm"
          onClick={handleDeleting}
          disabled={makingRequest}
        >
          {mobile ? "Delete" : <FontAwesomeIcon icon={faTrash} />}
        </Button>
      )}
    </div>
  );
};

// currently only user cells use this, hence email instead of index etc.
// backend uses email instead of _id as in some funcs that call this, we don't
// have access to _id
export const DeactivateCell = ({
  props: {
    row: {
      index,
      original: { email, superAdmin, deactivated, _id },
    },
    updateMyData,
  },
  api,
}) => {
  const [value, setValue] = useState(deactivated);
  const [makingRequest, setMakingRequest] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setValue(deactivated);
  }, [deactivated]);

  const onChange = () => {
    setValue(!value);
    setMakingRequest(true);

    api({ email, deactivated: !value })
      .then(({ data }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          updateMyData({
            type: "update-data",
            index,
            user: data.user,
          });
        }
      })
      .catch(({ response }) => {
        return updateMyData({
          type: "error",
          response,
        });
      });
  };

  return (
    <Form>
      <Form.Check
        disabled={superAdmin || makingRequest}
        type="switch"
        label=""
        id={`deactivate-switch-${_id}`}
        onChange={onChange}
        checked={value}
      />
    </Form>
  );
};

// Create an editable cell renderer
export const ImageCell = ({
  props: {
    row: {
      index,
      original: { _id, userId },
    },
    column: { id },
    updateMyData,
  },
  api,
  tableIndex,
  mobile = false,
  disabled = false,
}) => {
  const [makingRequest, setMakingRequest] = useState(false);
  const [hash, setHash] = useState(Date.now());
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleImage = (image) => {
    setMakingRequest(true);
    api({ image }, _id)
      .then(({ data }) => {
        if (isMounted.current) {
          setMakingRequest(false);
          setHash(Date.now());

          return updateMyData({
            type: "update-data",
            index,
            data,
            tableIndex,
          });
        }
      })
      .catch(({ response }) => {
        return updateMyData({
          type: "error",
          response,
        });
      });

    return;
  };

  return (
    <Form className={!mobile && "table-form"}>
      <FlexRow justifyContent={!mobile && "center"}>
        <FileUpload
          preview={`${imageUrl}/${userId}/products/${_id}`}
          setForm={(image) => handleImage(image)}
          controlId={`${id}-form-${_id}`}
          table={true}
          srcHash={hash}
          dispatch={updateMyData}
          disabled={disabled || makingRequest}
          mobile={mobile}
        />
      </FlexRow>
    </Form>
  );
};
