// much easier for us to calculate this in the front end as if we edit the VAT which is a user field so we just ping patchUser in the API, we would have to get all the services and calculate their RRP whenever we edited a user. If we do in the FE, we can just pass it in as an argument and it will update when necessary.

// calcRRP for a service works out the minimum RRP price we should charge for a service based on the user and service info
// duration should be in MINUTES
export const calcRRP = (duration, cost, user) => {
  if (+duration < 0 || +cost < 0 || +user.hourlyTotal < 0 || +user.vat < 0)
    return 0;

  return +(
    (+user.hourlyTotal * (+duration / 60) + +cost) *
    (1 + +user.vat / 100)
  ).toFixed(2);
};

export const calcOutgoingCost = (cost, regularity) => {
  if (cost < 0 || regularity < 0) return 0;

  return +(+cost * +regularity).toFixed(2);
};

// calcGrossProfit is the same as RRP but without the total annual outgoings and the cost added on. this represents the profit per service done.
export const calcGrossProfit = (duration, user) => {
  if (+duration < 0 || +user.grossHourlyProfit < 0 || +user.vat < 0) return 0;

  return +(
    +user.grossHourlyProfit *
    (+duration / 60) *
    (1 + +user.vat / 100)
  ).toFixed(2);
};

// calcRRp for a service works out the minimum RRP price we should charge for a service based on the user and service info
// duration should be in MINUTES
export const calcDifference = (duration, cost, charge, user) => {
  if (
    +duration < 0 ||
    +cost < 0 ||
    +charge < 0 ||
    +user.hourlyTotal < 0 ||
    +user.vat < 0
  )
    return 0;

  const rrp = calcRRP(duration, cost, user);

  const diff = +(+charge - +rrp).toFixed(2);

  return diff > 0 ? `+${diff.toFixed(2)}` : diff.toFixed(2);
};

export const formatDurationText = (duration) => {
  let hours = Math.floor(duration / 60);
  let minutes = duration % 60;
  if (hours === 0) {
    return `${minutes} minutes`;
  }
  if (hours === 1) {
    return `1 hour ${minutes} minutes`;
  }

  return `${hours} hours ${minutes} minutes`;
};

export const calcCapacity = (staff) => {
  if (!staff.hours || !staff.availableHours) return 0;

  return +((staff.hours / staff.availableHours) * 100).toFixed(1);
};
