import { createGlobalStyle } from "styled-components/macro";

export default createGlobalStyle`

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-display: fallback;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: ${(props) => props.theme.htmlBg};
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* global bootstrap */

.spinner {
  margin: ${(props) => props.theme.loadingMargin};
  align-self: center;
}

.accordion {
  .card {
    .card-header {
      background: ${(props) => props.theme.bysTheme};
    }
    .card-body {
      background: ${(props) => props.theme.bysThemeTrans1};
    }
  }
}

.form-control {
  :focus {
  border-color: ${(props) => props.theme.bysThemeTrans};
  box-shadow: ${(props) => props.theme.boxShadow};
  }
}

.btn-primary {
  background-color: ${(props) => props.theme.bysTheme};
  border-color: ${(props) => props.theme.bysTheme};

  :focus, &&&&&:active { /* specificity to get rid of box shadow at moment of being clicked as was staying blue */
  background-color: ${(props) => props.theme.bysTheme};
  border-color: ${(props) => props.theme.bysTheme};
  box-shadow: ${(props) => props.theme.boxShadow};
  }
}

.dropdown-toggle {
  background: inherit;
  border: none;
  color: ${(props) => props.theme.textC};

  :focus, &&&&&:active {
    background: inherit;
    border: none;
    box-shadow: none;
    color: ${(props) => props.theme.textC};
  }
}

&&&&.show>.btn-primary.dropdown-toggle {
  background: inherit;
  border: none;
  color: ${(props) => props.theme.textC};

  :focus {
    box-shadow: none;
  }
}

.dropdown-item:active {
  background-color: ${(props) => props.theme.bysTheme};
}

/* global modal styling */

.help-modal {
  p {
    margin-bottom: 0rem;
  }
}

/* global help buttons */

.help{
  .helpIcon {
    padding: 0;
    border: none;
    outline: none;
    font: inherit;
    color: ${(props) => props.theme.bysTheme};
    background: none;
    margin-left: 0.2rem;

    :focus {
      outline: 1px dotted;
    }
  }
}

/* global table styles */

.table {
  margin-bottom: 2rem;

  td {
    vertical-align: middle;
  }
}

table {
  border: ${(props) => props.theme.border};
  border-radius: ${(props) => props.theme.borderR};
  /* overflow: hidden; */
  text-align: center;
  margin-bottom: 0rem;

  thead > tr {
    background-color: ${(props) => props.theme.bysTheme};

    th {
      vertical-align: middle;
    }
  }

  tr:nth-child(even) {
    background-color: ${(props) => props.theme.bysThemeTrans};
  }

  td {
    vertical-align: middle;

    .table-form {
      margin-bottom: 0rem;

      .form-group {
        margin-bottom: 0rem;
      }
    }
  }

  .table-input {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: inherit;
    width: 100%;
    border-radius: ${(props) => props.theme.borderR};

    :focus {
      border-color: ${(props) => props.theme.bysThemeTrans};
      box-shadow: ${(props) => props.theme.boxShadow};
      outline: none;
      background: #fff;
    }
  }

  .number-input {
    max-width: 6rem;
    min-width: 4rem;
  }

  .fa-sort-up,
  .fa-sort-down {
    vertical-align: middle;
    margin-left: 0.2rem;
  }
}

/* global mobile table styles */

.mobile-table {
  margin-bottom: 1rem;

  .mobile-table-column {
    padding: 0.75rem 0.5rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #fff;

    .accordion-button {
      background: inherit;
      border: none;
      color: #fff;
      text-align: left;

      :focus {
        background: inherit;
        border: none;
        color: #fff;
      }
    }
  }
}

/* global text styling */

.capitalize {
  text-transform: capitalize;  
}

.bold {
  font-weight: bold;
}

/* global header styles */

        
h1, h2, h3, h4, h5, h6 {
  font-family: ${(props) => props.theme.headerFontFam};
  color: ${(props) => props.theme.headerC};
}

/* initial spinner styles */

.initial-spinner {
  grid-area: main;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.App {
  background-color: ${(props) => props.theme.htmlBg};
  text-align: left;
  font-family: ${(props) => props.theme.fontFam};
  color: ${(props) => props.theme.textC};
  min-height: 100vh;
  display: grid;
  grid-template-columns: 8vw auto 8vw;
  grid-template-rows: 3.5rem 1rem auto 1rem auto;
  grid-template-areas:
    "nav nav nav"
    ". . ."
    ". main ."
    ". . ."
    "privacy privacy privacy";
}

@media (hover: hover) {
  .btn-primary:hover {
    background-color: ${(props) => props.theme.bysThemeTrans};
    border-color: ${(props) => props.theme.bysThemeTrans};
  }

  .dropdown-toggle:hover {
    background: inherit;
    border: none;
    color: ${(props) => props.theme.textC};
  }

  .table-input:hover {
    border-color: ${(props) => props.theme.bysThemeTrans};
    box-shadow: ${(props) => props.theme.boxShadow};
    outline: none;
    background: #fff;
  }
  
  .table-hover tbody tr:hover {
    background-color: ${(props) => props.theme.bysThemeTrans1};
  }
}

@media (max-width: 1050px) {
  .App {
    display: grid;
    grid-template-columns: 2vw auto 2vw;
    grid-template-rows: 3.5rem 2vw auto 2vw auto;
  }
}

@media (max-width: 768px) {
  .App {
    display: grid;
    grid-template-columns: 2vw auto 2vw;
    grid-template-rows: auto 2vw auto 2vw auto;
  }
}
`;
