import React, { useEffect, useState } from "react";

import { useForm } from "../../hooks";
import * as api from "../../api";
import { gs } from "../../stores";
import { Next, GoBack, Help } from "../buttons";

import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { observer } from "mobx-react";
import NoMarginP from "../styles/NoMarginP";

const WeeksWorked = observer(() => {
  const {
    form,
    handleChange,
    setForm,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    weeksWorked: "",
  });
  const [user, setUser] = useState({});

  useEffect(() => {
    api
      .getUser()
      .then(({ data }) => {
        if (isMounted.current) return setUser(data.user);
      })
      .catch(({ response }) => {
        return (gs.err = {
          response,
          route: "/weeks-worked",
        });
      });
  }, [isMounted]);

  if (!gs.isAuthenticated()) return gs.redirect();

  const handleWeeksWorked = (e) => {
    e.preventDefault();
    setInvalid(false);

    if (
      +form.weeksWorked < 0 ||
      isNaN(+form.weeksWorked) ||
      +form.weeksWorked > 52
    ) {
      return setInvalid(true);
    }

    if (+user.weeksWorked !== +form.weeksWorked) {
      setMakingRequest(true);
      return api
        .patchUser({ weeksWorked: form.weeksWorked })
        .then((user) => {
          if (isMounted.current) {
            setUser(user);
            setForm((c) => {
              c.weeksWorked = "";
            });
            setMakingRequest(false);
          }
        })
        .catch(({ response }) => {
          return (gs.err = {
            response,
            route: "/weeks-worked",
          });
        });
    }

    return setForm((c) => {
      c.weeksWorked = "";
    });
  };

  return (
    <Styles>
      <div className="header-help">
        <h2>Weeks Worked</h2>
        <Help bodyKey={"weeksworked"} />
      </div>
      <div className="total-outgoings">
        <p>
          Total Monthly Outgoings (Income & Business Outgoings):{" "}
          {((+user.totalAnnualOutgoings + +user.income) / 12).toFixed(2)}
        </p>
        <p>
          Total Yearly Outgoings (Income & Business Outgoings):{" "}
          {(+user.totalAnnualOutgoings + +user.income).toFixed(2)}
        </p>
      </div>
      <Form onSubmit={handleWeeksWorked} className="patch-weeks-worked-form">
        <Form.Group controlId="weeks-worked-form">
          <Form.Label>
            <p>
              Number of weeks worked per year? If you work on your own and want
              to take 8 weeks off per year, then input 44 (52 - 8).
            </p>
            <NoMarginP>
              If you have a team and have different amounts of weeks off, just
              input the average number of weeks worked per year.
            </NoMarginP>
          </Form.Label>
          <Form.Control
            type="text"
            value={form.weeksWorked}
            onChange={(e) => handleChange(e, "weeksWorked")}
            required
            placeholder="Weeks worked. E.g. 46"
            isInvalid={invalid}
            autoComplete="off"
          />
          <Form.Control.Feedback type="invalid">
            Weeks worked can only be a number between 0 and 52
          </Form.Control.Feedback>
        </Form.Group>
        <Button disabled={makingRequest} size="sm" type="submit">
          Submit
        </Button>
      </Form>
      <p className="weeks-worked">
        Weeks worked per year: {(+user.weeksWorked).toFixed(1)}
      </p>
      <div className="totals">
        <p>Weekly total: {user.weeklyTotal}</p>
      </div>
      <div className="footer">
        <GoBack route="/outgoings" />
        <Next route="/business-breakdown" />
      </div>
    </Styles>
  );
});

export default WeeksWorked;

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .header-help {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .total-outgoings {
    margin: 2rem 0;
    font-weight: bold;
  }

  .patch-weeks-worked-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    .form-control {
      max-width: 300px;
    }
  }

  .weeks-worked {
    margin: 1rem 0rem 2rem 0rem;
    font-weight: bold;
  }

  .totals {
    margin-bottom: 2rem;
    font-weight: bold;
  }

  .footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;
