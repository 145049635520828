import React from "react";

import * as api from "../../api";
import { useForm } from "../../hooks";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Vat = ({ dispatch, vat }) => {
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    vat: "",
  });

  const handleVat = (e) => {
    e.preventDefault();
    setInvalid(false);

    if (+form.vat < 0 || +form.vat > 100 || isNaN(+form.vat)) {
      return setInvalid(true);
    }

    if (+vat !== +form.vat) {
      setMakingRequest(true);
      return api
        .patchUser({ vat: form.vat })
        .then((user) => {
          if (isMounted.current) {
            dispatch({ type: "update-user", user });
            setMakingRequest(false);
            setForm((c) => {
              c.vat = "";
            });
          }
        })
        .catch(({ response }) => {
          dispatch({
            type: "error",
            response,
          });
        });
    }

    return setForm((c) => {
      c.vat = "";
    });
  };

  return (
    <>
      <Form className="patch-vat-form" onSubmit={handleVat}>
        <Form.Group controlId="vat-form">
          <Form.Label>
            Use this box to add a % on top of your prices. This may be to add
            VAT/sales tax, and/or to include card processing fees.
          </Form.Label>
          <Form.Control
            placeholder="E.g. 20"
            type="text"
            value={form.vat}
            onChange={(e) => handleChange(e, "vat")}
            required
            isInvalid={invalid}
            autoComplete="off"
          />
          <Form.Control.Feedback type="invalid">
            Percentage must be between 0 - 100, no currency symbols or commas.
          </Form.Control.Feedback>
        </Form.Group>
        <Button type="submit" size="sm" disabled={makingRequest}>
          Submit
        </Button>
      </Form>
      <p className="vat">Percentage added: {+vat}%</p>
    </>
  );
};

export default Vat;
