import React, { useEffect, useState } from "react";

import { gs } from "./stores";
import * as api from "./api";

import Navigation from "./components/routing/Navigation";
import ErrorPage from "./components/errors/ErrorPage";
import SetPrivacyCookie from "./components/privacy/SetPrivacyCookie";
import Routes from "./components/routing/Routes";

import "bootstrap/dist/css/bootstrap.min.css";
import { observer } from "mobx-react";
import Spinner from "react-bootstrap/Spinner";
import throttle from "lodash.throttle";
import { navigate } from "@reach/router";

const App = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  gs.isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const handleResize = throttle((e) => {
      if (window.innerWidth <= 768) {
        gs.isMobile = true;
      } else {
        gs.isMobile = false;
      }
    }, 500);

    const handleTouch = () => {
      // set global state to touch device
      gs.isTouch = true;

      // we only need to know once that a human touched the screen, so we can stop listening now
      window.removeEventListener("touchstart", handleTouch);
    };

    // check if mobile, < 768 px
    window.addEventListener("resize", handleResize);

    // listener to detect if a user is using touch to navigate.
    // need to disable drag on table row if so
    window.addEventListener("touchstart", handleTouch);

    api
      .loggedIn()
      .then(({ loggedIn, user }) => {
        if (loggedIn) {
          gs.setUserData(user);
        }
        // don't redirect user to login if user is resetting password
        if (!loggedIn && !/reset/g.test(window.location.pathname))
          navigate("/login");
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setIsLoading(false);
        gs.err = { response, route: "/" };
      });

    return () => {
      // remove eventListener when leaving app
      window.removeEventListener("resize", handleResize);
      // need to remove if it hasn't been already
      window.removeEventListener("touchstart", handleTouch);
    };
  }, []);

  return (
    <div className="App">
      <Navigation />
      {isLoading ? (
        <div className="initial-spinner">
          <Spinner animation="border" className="spinner" />
        </div>
      ) : gs.err ? (
        <ErrorPage err={gs.err} />
      ) : (
        <Routes />
      )}
      <SetPrivacyCookie />
    </div>
  );
});

export default App;
