import React from "react";

import * as api from "../../api";

import {
  DeleteCell,
  NumberCell,
  SelectCell,
  TextCell,
} from "../tables/EditableCells";
import PostService from "./PostService";

const MobileServiceForm = ({
  dispatch,
  index,
  service,
  tableId,
  tableIndex,
  products,
}) => {
  const { serviceName, totalCost, duration, serviceCharge, _id } = service;
  return (
    <>
      <TextCell
        props={{
          value: serviceName,
          row: { index, original: { _id } },
          column: { id: "serviceName", Header: "Service" },
          updateMyData: dispatch,
        }}
        api={api.patchService}
        tableId={tableId}
        tableIndex={tableIndex}
        mobile
        disabled
      />
      <NumberCell
        props={{
          value: totalCost,
          row: { index, original: { _id } },
          column: { id: "totalCost", Header: "Product/stock cost" },
          updateMyData: dispatch,
        }}
        api={api.patchService}
        tableId={tableId}
        tableIndex={tableIndex}
        mobile
        disabled
      />
      <SelectCell
        props={{
          value: duration,
          row: { index, original: { _id } },
          column: { id: "duration", Header: "Length" },
          updateMyData: dispatch,
        }}
        api={api.patchService}
        tableId={tableId}
        tableIndex={tableIndex}
        mobile
        disabled
      />
      <NumberCell
        props={{
          value: serviceCharge,
          row: { index, original: { _id } },
          column: { id: "serviceCharge", Header: "Current price" },
          updateMyData: dispatch,
        }}
        api={api.patchService}
        tableId={tableId}
        tableIndex={tableIndex}
        mobile
        disabled
      />
      <div className="edit-delete-service">
        <PostService
          index={index}
          dispatch={dispatch}
          tableId={tableId}
          tableIndex={tableIndex}
          api={api.patchService}
          products={products}
          initialData={service}
          mobile={true}
        />
        <DeleteCell
          props={{
            row: { index, original: { _id } },
            updateMyData: dispatch,
          }}
          api={api.deleteService}
          tableId={tableId}
          tableIndex={tableIndex}
          label={"service"}
          warning
          mobile
        />
      </div>
    </>
  );
};

export default MobileServiceForm;
