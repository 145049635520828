import React, { useMemo } from "react";

import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import MobileTableRow from "./MobileTableRow";

const MobileTableComponent = ({
  data,
  columns,
  dispatch,
  buttonTextFormatter,
}) => {
  return useMemo(() => {
    return (
      <Accordion defaultActiveKey="0" className="mobile-table">
        {data.map((row, i) => {
          return (
            <Card key={`mobile-form-row-${row._id}`}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={`${i}`}
                className="mobile-table-column"
              >
                <button className="accordion-button">
                  {buttonTextFormatter(row)}
                </button>
                <FontAwesomeIcon icon={faAngleDown} className="arrowIcon" />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={`${i}`}>
                <MobileTableRow
                  row={row}
                  columns={columns}
                  index={i}
                  dispatch={dispatch}
                />
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    );
  }, [data, dispatch, columns, buttonTextFormatter]);
};

export default MobileTableComponent;
