import React, { useState } from "react";
import FormData from "form-data";

import { useForm } from "../../hooks";
import * as api from "../../api";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import SelectOptions from "../tables/SelectOptions";
import FileUpload from "../common/FileUpload";

const initialForm = {
  name: "",
  size: 1,
  unit: "uses",
  cost: 0.0,
  image: "",
  preview: "",
};

const PostProduct = ({ dispatch }) => {
  const {
    form,
    handleChange,
    setForm,
    isMounted,
    makingRequest,
    setMakingRequest,
    invalid: invalidSize,
    setInvalid: setInvalidSize,
  } = useForm({ ...initialForm });
  const [invalidCost, setInvalidCost] = useState(false);

  const handlePostProduct = (e) => {
    e.preventDefault();
    setInvalidSize(false);
    setInvalidCost(false);

    if (form.size === "" || +form.size < 1 || isNaN(+form.size)) {
      return setInvalidSize(true);
    }

    if (form.cost === "" || +form.cost < 0 || isNaN(+form.cost)) {
      return setInvalidCost(true);
    }

    setMakingRequest(true);

    const formData = new FormData();
    formData.append("name", form.name);
    formData.append("size", form.size);
    formData.append("unit", form.unit);
    formData.append("cost", form.cost);
    formData.append("image", form.image);

    api
      .postProduct(formData)
      .then((product) => {
        if (isMounted.current) {
          dispatch({ type: "add-data", product });
          setForm(() => {
            return { ...initialForm };
          });

          setMakingRequest(false);
        }
      })
      .catch(({ response }) => {
        dispatch({
          type: "error",
          response,
        });
      });
  };

  return (
    <Form onSubmit={handlePostProduct} className="post-product-form">
      <Form.Group controlId="post-product-name">
        <Form.Label>Product name</Form.Label>
        <Form.Control
          type="text"
          value={form.name}
          onChange={(e) => handleChange(e, "name")}
          required
          placeholder="Product name. E.g. Tub of Loreal bleach powder"
        />
      </Form.Group>
      <div className="flex-row">
        <Form.Group controlId="post-product-size">
          <Form.Label>Container Size</Form.Label>
          <Form.Control
            as="input"
            type="text"
            value={form.size}
            onChange={(e) => handleChange(e, "size")}
            required
            placeholder="E.g 1000"
            isInvalid={invalidSize}
          />
          <Form.Control.Feedback type="invalid">
            Must be a positive, none zero number, no symbols or commas.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="post-product-unit">
          <Form.Label>Unit</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => handleChange(e, "unit")}
            value={form.unit}
            disabled={makingRequest}
          >
            <SelectOptions id={"unit"} />
          </Form.Control>
        </Form.Group>
      </div>
      <Form.Group controlId="post-product-cost">
        <Form.Label>Total container cost</Form.Label>
        <Form.Control
          as="input"
          type="text"
          value={form.cost}
          onChange={(e) => handleChange(e, "cost")}
          required
          placeholder="E.g 10.00"
          isInvalid={invalidCost}
        />
        <Form.Control.Feedback type="invalid">
          Must be a positive number, no currency symbols or commas.
        </Form.Control.Feedback>
      </Form.Group>
      <FileUpload
        preview={form.preview}
        setForm={(file, preview) => {
          setForm((c) => {
            c.preview = preview;
            c.image = file;
          });
        }}
        controlId="post-product-cost"
        dispatch={dispatch}
        disabled={makingRequest}
      />
      <Button disabled={makingRequest} size="sm" type="submit">
        Add product
      </Button>
    </Form>
  );
};

export default PostProduct;
