import React, { useMemo } from "react";

import * as api from "../../api";
import TableComponent from "../tables/TableComponent";
import {
  TextCell,
  DeleteCell,
  NumberCell,
  SelectCell,
  ImageCell,
} from "../tables/EditableCells";
import MobileTableComponent from "../tables/MobileTableComponent";

const ProductsTable = ({ dispatch, data, mobile }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Image",
        accessor: "image", // this isn't used but ensure mobile component renders cell
        Cell: (props) => ImageCell({ props, api: api.patchProduct, mobile }),
      },
      {
        Header: "Product",
        accessor: "name",
        Cell: (props) => TextCell({ props, api: api.patchProduct, mobile }),
      },
      {
        Header: "Container size",
        accessor: "size",
        Cell: (props) =>
          NumberCell({
            props,
            api: api.patchProduct,
            mobile,
            fixed: 0,
          }),
      },
      {
        Header: "Unit",
        accessor: "unit",
        Cell: (props) =>
          SelectCell({
            props,
            api: api.patchProduct,
            mobile,
            number: false,
          }),
      },
      {
        Header: "Cost",
        accessor: "cost",
        Cell: (props) => NumberCell({ props, api: api.patchProduct, mobile }),
      },
      {
        Header: "Delete",
        accessor: "delete",
        Cell: (props) => DeleteCell({ props, api: api.deleteProduct, mobile }),
      },
    ],
    [mobile]
  );

  return mobile ? (
    <MobileTableComponent
      data={data}
      columns={columns}
      dispatch={dispatch}
      buttonTextFormatter={(product) =>
        `${product.name}, cost ${(+product.cost).toFixed(2)} for ${
          product.size
        }${product.unit === "uses" ? " " + product.unit : product.unit}.`
      }
    />
  ) : (
    <TableComponent columns={columns} data={data} updateMyData={dispatch} />
  );
};

export default ProductsTable;
