import axios from "axios";
import FormData from "form-data";
// built files are built in a production environment. from npm run build
const ENV = process.env.NODE_ENV || "development";
const baseUrl = process.env.REACT_APP_API_HOST;

// send requests with credentials to send cookies
axios.defaults.withCredentials = true;

export const postLogin = (email, password) => {
  return axios
    .post(`${baseUrl}/login`, { email, password })
    .then((res) => res.data.user);
};

export const getLogout = () => {
  return axios.get(`${baseUrl}/logout`);
};

export const loggedIn = () => {
  return axios.get(`${baseUrl}/login/loggedin`).then((res) => res.data);
};

export const getUser = () => {
  return axios.get(`${baseUrl}/users`);
};

export const patchUser = (userObj) => {
  return axios.patch(`${baseUrl}/users`, userObj).then((res) => res.data.user);
};

export const getOutgoings = () => {
  return axios.get(`${baseUrl}/outgoings`);
};

export const postOutgoing = (outgoing) => {
  return axios
    .post(`${baseUrl}/outgoings`, outgoing)
    .then((res) => res.data.outgoing);
};

export const patchOutgoing = (outgoing, outgoingId) => {
  return axios.patch(`${baseUrl}/outgoings/${outgoingId}`, outgoing);
};

export const deleteOutgoing = (outgoingId) => {
  return axios.delete(`${baseUrl}/outgoings/${outgoingId}`);
};

export const getStaff = () => {
  return axios.get(`${baseUrl}/staff`);
};

export const postStaff = (staffMember) => {
  return axios.post(`${baseUrl}/staff`, staffMember).then((res) => res.data);
};

export const patchStaff = (staffMember, staffId) => {
  return axios.patch(`${baseUrl}/staff/${staffId}`, staffMember);
};

export const deleteStaff = (staffId) => {
  return axios.delete(`${baseUrl}/staff/${staffId}`);
};

export const getProducts = () => {
  return axios.get(`${baseUrl}/products`);
};

export const postProduct = (formData) => {
  return axios
    .post(`${baseUrl}/products`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data.product);
};

export const patchProduct = (product, productId) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(product)) {
    formData.append(key, value);
  }
  return axios.patch(`${baseUrl}/products/${productId}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteProduct = (productId) => {
  return axios.delete(`${baseUrl}/products/${productId}`);
};

export const getServices = () => {
  return axios.get(`${baseUrl}/services`).then((res) => res.data.services);
};

export const postService = (service, categoryId) => {
  return axios.post(`${baseUrl}/services/${categoryId}`, service);
};

export const patchService = (service, categoryId, updateIndex) => {
  return axios.patch(`${baseUrl}/services/${service._id}/${categoryId}`, {
    updateIndex,
    ...service,
  });
};

export const moveService = (categoryId, pushIndex, pullIndex) => {
  return axios.patch(`${baseUrl}/services/move/${categoryId}`, {
    pushIndex,
    pullIndex,
  });
};

export const moveServiceCategory = (
  pushCategoryId,
  pullCategoryId,
  pushIndex,
  pullIndex
) => {
  return axios.patch(
    `${baseUrl}/services/move/${pushCategoryId}/${pullCategoryId}`,
    {
      pushIndex,
      pullIndex,
    }
  );
};

export const deleteService = (serviceId, categoryId) => {
  return axios.delete(`${baseUrl}/services/${serviceId}/${categoryId}`);
};

export const getCategories = () => {
  return axios.get(`${baseUrl}/categories`);
};

export const postCategory = (category) => {
  return axios
    .post(`${baseUrl}/categories`, category)
    .then((res) => res.data.category);
};

export const patchCategory = (category, categoryId) => {
  return axios.patch(`${baseUrl}/categories/${categoryId}`, category);
};

export const deleteCategory = (categoryId) => {
  return axios.delete(`${baseUrl}/categories/${categoryId}`);
};

export const forceChangePassword = (password1, password2) => {
  return axios.patch(`${baseUrl}/password/change`, {
    password1,
    password2,
  });
};

export const emailResetPassword = (email) => {
  return axios.post(`${baseUrl}/password/reset`, { email });
};

export const changeEmail = (newEmail, newEmail2, password) => {
  return axios.patch(`${baseUrl}/users/account`, {
    newEmail,
    newEmail2,
    password,
  });
};

export const changePassword = (newPassword, newPassword2, password) => {
  return axios.patch(`${baseUrl}/users/account`, {
    newPassword,
    newPassword2,
    password,
  });
};

export const deleteAccount = (password) => {
  // need to put request body on a data key with axios when sending information with a delete request
  return axios.delete(`${baseUrl}/users`, { data: { password } });
};

export const getUsers = () => {
  return axios.get(`${baseUrl}/users/all`);
};

export const postUser = (email) => {
  return axios
    .post(`${baseUrl}/users/admin`, { email })
    .then((res) => res.data.user);
};

export const deleteUserAdmin = (_id) => {
  // need to put request body on a data key with axios when sending information with a delete request
  return axios.delete(`${baseUrl}/users/admin`, { data: { _id } });
};

export const patchUserAdmin = (data) => {
  return axios.patch(`${baseUrl}/users/admin`, data);
};

export const resetPassword = (password1, password2, token) => {
  return axios.patch(
    `${baseUrl}/password/reset`,
    { password1, password2 },
    {
      headers: { Authorization: "BEARER " + token },
    }
  );
};
