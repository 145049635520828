import React, { useEffect } from "react";

import { gs } from "../../stores";

import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { navigate, Redirect } from "@reach/router";

const Styles = styled.main`
  grid-area: main;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const ErrorPage = ({ err: { response, route } }) => {
  const handleError = () => {
    gs.err = null;
    navigate(route);
  };

  useEffect(() => {
    if (response !== undefined) {
      if (response.status === 401) {
        gs.err = null;
        // need to set loggedIn to null here despite it being set in logout(), to stop an error in the browser:
        /* the service worker navigation preload request was cancelled before 'preloadresponse' settled. if you intend to use 'preloadresponse', use waituntil() or respondwith() to wait for the promise to settle */
        gs.loggedIn = null;
        gs.logout();
      }
    }
  }, [response]);

  if (response !== undefined) {
    if (response.status === 401) {
      return <Redirect noThrow={true} to="/login" />;
    }
  }

  return (
    <Styles>
      <h2>Error: {response ? response.status : "500"}</h2>
      <h3>{response ? response.data.msg : "Oops! Something went wrong!"}</h3>
      <Button onClick={handleError} size="sm">
        Go Back!
      </Button>
    </Styles>
  );
};

export default ErrorPage;
