import React from "react";

import { useForm } from "../../hooks";
import * as api from "../../api";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const PostUser = ({ dispatch }) => {
  const {
    form,
    setForm,
    handleChange,
    invalid,
    setInvalid,
    makingRequest,
    setMakingRequest,
    isMounted,
  } = useForm({
    email: "",
  });

  const handlePostUser = (e) => {
    setMakingRequest(true);
    e.preventDefault();

    api
      .postUser(form.email)
      .then((user) => {
        if (isMounted.current) {
          dispatch({ type: "add-data", user });
          setForm((c) => {
            c.email = "";
          });
          setMakingRequest(false);
        }
      })
      .catch(({ response }) => {
        if (isMounted.current) setMakingRequest(false);

        switch (response?.data?.msg) {
          case "Data already exists!":
            return isMounted.current && setInvalid(true);
          default:
            return dispatch({
              type: "error",
              response,
            });
        }
      });
  };

  return (
    <Form onSubmit={handlePostUser} className="post-user-form" size="sm">
      <Form.Group controlId="user-form">
        <Form.Label>Add user</Form.Label>
        <Form.Control
          type="email"
          value={form.email}
          onChange={(e) => handleChange(e, "email")}
          required
          placeholder="Email."
          isInvalid={invalid}
        />
        <Form.Control.Feedback type="invalid">
          This email is already registered!
        </Form.Control.Feedback>
      </Form.Group>
      <Button disabled={makingRequest} size="sm" type="submit">
        Add user
      </Button>
    </Form>
  );
};

export default PostUser;
