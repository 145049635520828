import React from "react";

const TableRow = ({ row, role }) => {
  return (
    <tr role={role}>
      {row.cells.map((cell) => {
        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
      })}
    </tr>
  );
};

// use React.memo to only rerender the row when its data has changed
// https://reactjs.org/docs/react-api.html#reactmemo
const areEqual = (prevProps, nextProps) => {
  return prevProps.row.original === nextProps.row.original;
};

export default React.memo(TableRow, areEqual);
