import React, { useEffect, useRef, useState } from "react";

import { useToggle } from "../../hooks";
import * as api from "../../api";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const DeleteCategory = ({ name, tableId, dispatch, tableIndex }) => {
  const [deleteCategory, haandleDeleting] = useToggle();
  const [makingRequest, setMakingRequest] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDeleteCategory = () => {
    setMakingRequest(true);
    api
      .deleteCategory(tableId)
      .then(() => {
        if (isMounted.current) {
          setMakingRequest(false);
          haandleDeleting();
          return dispatch({ type: "delete-category", tableIndex });
        }
      })
      .catch(({ response }) => {
        return dispatch({
          type: "error",
          response,
        });
      });
    return;
  };

  return (
    <div className="delete-category">
      <Modal
        show={deleteCategory}
        onHide={haandleDeleting}
        aria-labelledby="modal-delete-category"
        // same styling as help-modal
        className="help-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete {name}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="delete-category-body">
            Are you sure you want to delete {name}? Any services associated with
            this category will also be deleted and cannot be recovered.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            size="sm"
            onClick={handleDeleteCategory}
            disabed={makingRequest}
          >
            Delete
          </Button>
          <Button variant="secondary" onClick={haandleDeleting} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {!deleteCategory && (
        <OverlayTrigger
          overlay={<Tooltip id="tooltip">Delete {name}!</Tooltip>}
        >
          <Button
            variant="danger"
            size="sm"
            onClick={haandleDeleting}
            disabled={makingRequest}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default DeleteCategory;
