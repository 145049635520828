import React from "react";

import Income from "../income/Income";
import Login from "../login/Login";
import Outgoings from "../outgoings/Outgoings";
import Overheads from "../overheads/Overheads";
import Services from "../services/Services";
import ForceChangePassword from "../reset-password/ForceChangePassword";
import ResetPasswordEmail from "../reset-password/ResetPasswordEmail";
import ResetPassword from "../reset-password/ResetPassword";
import Account from "../account/Account";
import Home from "../home/Home";
import Overview from "../overview/Overview";
import NotFound from "../errors/NotFound";
import PrivacyPolicy from "../privacy/PrivacyPolicy";

import { Router } from "@reach/router";
import styled from "styled-components";
import Products from "../products/Products";
import WeeksWorked from "../weeksworked/WeeksWorked";

const Routes = () => {
  return (
    <Styles>
      <Router>
        <Login path="/login" />
        <Home path="/" />
        <Income path="/income" />
        <Outgoings path="/outgoings" />
        <WeeksWorked path="/weeks-worked" />
        <Overheads path="/business-breakdown" />
        <Products path="/products" />
        <Services path="/services" />
        <ForceChangePassword path="/change-password" />
        <ResetPasswordEmail path="/reset" />
        <ResetPassword path="/reset/:token" />
        <Account path="/account" />
        <Overview path="/overview" />
        <PrivacyPolicy path="/privacypolicy" />
        <NotFound default />
      </Router>
    </Styles>
  );
};

export default Routes;

const Styles = styled.main`
  grid-area: main;
`;
