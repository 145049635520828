import styled from "styled-components";

const FlexRow = styled.div`
  display: flex;
  flex: row nowrap;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
`;

export default FlexRow;
