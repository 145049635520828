import React from "react";

import { useInit } from "../../hooks";
import * as api from "../../api";
import { gs } from "../../stores";
import { Next, GoBack, Help } from "../buttons";
import PostProduct from "./PostProduct";
import ProductsTable from "./ProductsTable";

import { observer } from "mobx-react";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";

const Products = observer(() => {
  const [state, dispatch] = useInit(reducer, initialState, {
    api: api.getProducts,
    type: "set-data",
  });

  if (!gs.isAuthenticated()) return gs.redirect();

  return (
    <Styles>
      <div className="header-help">
        <h2>Product/Stock Library</h2>
        <Help bodyKey="products" />
      </div>

      <div className="description">
        <p>
          This is where you can input what you pay for your stock, e.g. ‘1 x
          1000ml’ bottle of peroxide, or a gel polish bottle with ‘20 x uses’,
          if you know that one container typically has 20 applications)
        </p>
        <p>
          On the next page, you will be able to add how much of each stock
          product you need to do each service, and it will work out the exact
          stock cost per service, for you.
        </p>
        <p>
          And if any of your stock prices change, simply update them in here and
          all of your service price RRP’s will automatically update on the next
          page, making future increases super easy!
        </p>
      </div>

      <PostProduct dispatch={dispatch} />

      {state.isLoading ? (
        <Spinner animation="border" className="spinner" />
      ) : (
        <ProductsTable
          data={state.products}
          dispatch={dispatch}
          mobile={gs.isMobile}
        />
      )}

      <div className="footer">
        <GoBack route="/business-breakdown" />
        <Next route="/services" />
      </div>
    </Styles>
  );
});

export default Products;

const reducer = (state, action) => {
  switch (action.type) {
    case "set-data":
      state.products = action.data.products;
      state.isLoading = false;

      return;
    case "delete-data":
      state.products = state.products.filter(
        (product, index) => index !== action.index
      );

      return;
    case "add-data":
      state.products = [...state.products, { ...action.product }];
      return;
    case "update-data":
      state.products[action.index] = action.data.product;

      return;
    case "error":
      gs.err = {
        response: action.response,
        route: "/products",
      };
      return;
    default:
      return state;
  }
};

const initialState = {
  products: [],
  isLoading: true,
};

const Styles = styled.div`
  display: flex;
  flex-flow: column nowrap;

  .header-help {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .description {
    margin-top: 2rem;
  }

  .post-product-form {
    margin-bottom: 1rem;

    .form-control {
      max-width: 300px;
    }

    .flex-row {
      display: flex;
      flex-flow: row nowrap;
    }

    .text-muted {
      font-size: 0.7rem;
    }
  }

  .footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;
